import React, { useEffect } from "react";
import { DateNew } from "../../functions/CustomeDate";
import { useState } from "react";
import { useMutationHooks } from "../../hooks/useMutationHook";
import * as SaleService from "../../services/SaleService";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";

function UpdateStatusLand(props) {
    const { item, statusOrder } = props;
    const id = item._id;
    const [showModal, setShowModal] = React.useState(false);
    const [note, setNote] = useState(item.note);
    const [status, setStatus] = useState("");
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const mutationEdit = useMutationHooks(async (data) => {
        const { id, ...rest } = data
        const res = await SaleService.updateStatusOrder(id, rest);
        return res;
    });

    const handleUpdate = async () => {
        if (!status) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Không được để trống!", Toastobjects);
            }
        }
        else {
            const data = {
                id,
                note,
                status
            }
            await mutationEdit.mutate(data)
        }
    }
    const { error, isLoading, isSuccess, isError } = mutationEdit;
    useEffect(() => {
        if (!error && isSuccess) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Update trạng thái thành công!",
                    Toastobjects
                );
            }
            window.location.reload()
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        }
    }, [error, isSuccess]);
    return (
        <>
            <Toast />
            <button
                onClick={() => setShowModal(true)}
                className={`px-3 py-2 rounded-lg text-white bg-[${statusOrder[item.status].bg
                    }]`}
            >
                {statusOrder[item.status].title}
            </button>

            {showModal && (
                <>
                    <div
                        id="status_bds"
                        tabIndex="-1"
                        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-3rem)] max-h-full flex"
                    >
                        <div className="relative p-4 w-full max-w-3xl max-h-full">
                            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 pb-3">
                                <div className="flex items-center p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                        Cập nhật trạng thái chăm sóc
                                    </h3>
                                    <button
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                        className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                    >
                                        <svg
                                            className="w-3 h-3"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 14 14"
                                        >
                                            <path
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                            ></path>
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <div className="p-4 md:p-5 space-y-4">
                                    <div className="flex gap-3">
                                        <a className="px-3 py-2 rounded-lg text-white bg-[#17a2b8]">
                                            Chưa tiếp cận
                                        </a>
                                        <a className="px-3 py-2 rounded-lg font-medium bg-[#FFC20E]">
                                            {item.phone_number}
                                        </a>
                                    </div>
                                    <p className="text-start text-base leading-relaxed text-gray-500">
                                        Nhân viên Sale:{" "}
                                        <span className="text-black">
                                            {" "}
                                            {item.created_by.fullname}
                                        </span>
                                    </p>
                                    <p className="text-start text-base leading-relaxed text-gray-500">
                                        Ngày phát sinh:{" "}
                                        <span className="text-black">
                                            {DateNew(item.updatedAt)}
                                        </span>
                                    </p>
                                    <p className="text-start text-base leading-relaxed text-gray-500">
                                        Dự án:{" "}
                                        <span className="text-black">{item?.project?.building}</span>
                                    </p>
                                    <p className="text-start text-base leading-relaxed text-gray-500">
                                        Ghi chú:{" "}
                                        <span className="text-black">{item?.project?.note}</span>
                                    </p>
                                </div>
                                <div className="p-4 md:p-5 space-y-4">
                                    <h4 className="text-start text-xl font-semibold text-gray-900 dark:text-white">
                                        Trạng thái
                                    </h4>
                                    <div className="flex gap-1">
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="khach_net"
                                                name="status"
                                                value="appointment"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="khach_net"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#FFC20E]  border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Hẹn xem nhà</span>
                                                </div>
                                            </label>
                                        </div>
                                       
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="khach_net"
                                                name="status"
                                                value="net"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="khach_net"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#28a745] border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Khách nét</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="khong_net"
                                                name="status"
                                                value="no_net"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="khong_net"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#dc3545] border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Không nét</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="da_coc"
                                                name="status"
                                                value="staked"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="da_coc"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#1c8032] border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Đã cọc</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="ky_hop_dong"
                                                name="status"
                                                value="contract"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="ky_hop_dong"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#337ab7] border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Ký hợp đồng</span>
                                                </div>
                                            </label>
                                        </div>
                                        <div className="">
                                            <input
                                                type="radio"
                                                id="mat"
                                                name="status"
                                                value="lost"
                                                className="hidden peer"
                                                onClick={(e) => setStatus(e.target.value)}

                                            />
                                            <label
                                                htmlFor="mat"
                                                className="inline-flex items-center justify-between w-full transition duration-300 px-3 py-2 text-white font-medium bg-[#babbbc] border border-gray-200 rounded-lg cursor-pointer peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100"
                                            >
                                                <div className="block">
                                                    <span>Mất</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="flex justify-between items-center md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                                    <h4 className="text-start text-xl font-semibold text-gray-900 dark:text-white">
                                        Thông tin chăm sóc
                                    </h4>
                                </div>
                                <textarea
                                    name="info"
                                    id="info"
                                    cols="30"
                                    rows="3"
                                    placeholder="Ghi chú"
                                    value={note}
                                    className="p-3 w-full max-w-2xl"
                                    onChange={(e) => setNote(e.target.value)}
                                ></textarea>
                                <button
                                    data-modal-hide="status_bds"
                                    type="button"
                                    onClick={handleUpdate}
                                    className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                >
                                    Gửi thông tin
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default UpdateStatusLand;
