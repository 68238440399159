import React from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../../components/Sidebar/Sidebar";
import withAuthorization from "../../../privateRouterv2";
import CallBack from "../../../components/Telesale/Callback/CallBack";

function CallBackPage() {
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
  return (
    <>
      <Sidebar />
      <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
        <CallBack />
      </div>
    </>
  );
}

export default withAuthorization(CallBackPage, [
  "super_admin",
  "telesale",
  "admin",
]);
