import * as SaleService from "../services/SaleService";

export const getAll = async (
  page,
  status = "",
  phone_number,
  fullname,
  access_token
) => {
  const res = await SaleService.getALl(
    page,
    status,
    phone_number,
    fullname,
    access_token
  );
  return res;
};

export const getAllRent = async (
  page,
  code,
  project,
  group,
  building,
  interior,
  kind_of,
  direction,
  bedroom_number,
  room,
  creadAt,
  updateAt,
  access_token,
  price_sale
) => {
  const res = await SaleService.getALLRent(
    page,
    code,
    project,
    group,
    building,
    interior,
    kind_of,
    direction,
    bedroom_number,
    room,
    creadAt,
    updateAt,
    access_token,
    price_sale
  );
  return res;
};
export const findAll = async (
  page,
  code,
  project,
  group,
  building,
  interior,
  kind_of,
  direction,
  bedroom_number,
  room,
  status,
  creadAt,
  updateAt,
  access_token,
  price_sale
) => {
  const res = await SaleService.findALl(
    page,
    code,
    project,
    group,
    building,
    interior,
    kind_of,
    direction,
    bedroom_number,
    room,
    status,
    creadAt,
    updateAt,
    access_token,
      price_sale
  );
  return res;
};
// export const fetchSaleById = async (id) => {
//   const res = await SaleService.getSaleById(id);
//   return res;
// };

// export const deleteSaleById = async (id) => {
//   const res = await SaleService.deleteSaleById(id);
//   return res;
// };
