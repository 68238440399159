import React, { useState } from "react";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast";
import * as UserService from "../../services/Userservice";
import { useMutationHooks } from "../../hooks/useMutationHook";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function AddUser() {
  const [fullname, setFullName] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [department, setDepartment] = useState("");
  const userLogin = useSelector((state) => state.user);
  const { access_token } = userLogin;
  const [roles, setRoles] = useState([]);
  const [position, setPosition] = useState("");
  const password = "12345678";
  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const mutationAddUsers = useMutationHooks((data) => {
    const { access_token, ...rest } = data;
    const res = UserService.createdUsers(rest, access_token);
    return res;
  });

  const handleRoleChange = (e) => {
    const roleValue = e.target.value;
    setRoles((prevRoles) => {
      if (prevRoles.includes(roleValue)) {
        // If the role is already in the array, remove it
        return prevRoles.filter((r) => r !== roleValue);
      } else {
        // If the role is not in the array, add it
        return [...prevRoles, roleValue];
      }
    });
  };
  const submitHandler = async (event) => {
    event.preventDefault();
    if (
      fullname === "" ||
      username === "" ||
      // phoneNumber === "" ||
      department === "" ||
      roles === ""
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Không được để trống!", Toastobjects);
      }
    } else {
      //   const access_token = JSON.parse(localStorage.getItem("access_token"))
      mutationAddUsers.mutate({
        fullname,
        username,
        phone_number: phoneNumber,
        department,
        roles,
        password,
        // email: "",
        position: position,
        access_token,
      });
    }
  };
  const { error, isLoading, isSuccess, isError } = mutationAddUsers;
  useEffect(() => {
    if (!error && isSuccess) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Tạo user thành công", Toastobjects);
      }
    } else if (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
  }, [error, isSuccess]);
  return (
    <>
      <Toast />
      <div className="w-full max-w-5xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow xs:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-3" action="#">
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Tạo tài khoản người dùng
          </h5>
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Họ và tên <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="name"
                name="fullname"
                onChange={(e) => setFullName(e.target.value)}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
                placeholder="Họ và tên"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Tài khoản <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="username"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Tài khoản"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="phone_number"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                SĐT
              </label>
              <input
                type="text"
                id="phone_number"
                name="phone_number"
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Số điện thoại"
              />
            </div>
          </div>
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                  htmlFor="department"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Bộ phận <span className="text-red-600">(*)</span>
              </label>
              <input
                  type="text"
                  id="department"
                  name="department"
                  onChange={(e) => setDepartment(e.target.value)}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Bộ phận"
              />
            </div>
            <div className="mb-5">
              <label
                  htmlFor="department"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Vị trí <span className="text-red-600">(*)</span>
              </label>
              <input
                  type="text"
                  id="department"
                  name="department"
                  onChange={(e) => setPosition(e.target.value)}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Bộ phận"
              />
            </div>
          </div>
          <div className="md:flex md:justify-center md:gap-2">
            <div className="mb-5 w-full max-w-3xl">
              <h3 className="font-semibold text-gray-900 dark:text-white">
                Phân quyền <span className="text-red-600">(*)</span>
              </h3>
              <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg min-[769px]:flex">
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="super_admin"
                      type="checkbox"
                      value="super_admin"
                      name="role"
                      checked={roles.includes("super_admin")}
                      onChange={handleRoleChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="super_admin"
                      className="py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Super Admin
                    </label>
                  </div>
                </li>
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="admin"
                      type="checkbox"
                      value="admin"
                      checked={roles.includes("admin")}
                      onChange={handleRoleChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="admin"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Admin
                    </label>
                  </div>
                </li>
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="manager"
                      type="checkbox"
                      value="manager"
                      checked={roles.includes("manager")}
                      onChange={handleRoleChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="manager"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Manager
                    </label>
                  </div>
                </li>
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="sale"
                      type="checkbox"
                      value="sale"
                      checked={roles.includes("sale")}
                      onChange={handleRoleChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="sale"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Sale
                    </label>
                  </div>
                </li>
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                      id="telesale"
                      type="checkbox"
                      value="telesale"
                      checked={roles.includes("telesale")}
                      onChange={handleRoleChange}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                      htmlFor="telesale"
                      className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Telesale
                    </label>
                  </div>
                </li>
                <li className="w-full">
                  <div className="flex items-center ps-3">
                    <input
                        id="intern"
                        type="checkbox"
                        value="intern"
                        checked={roles.includes("intern")}
                        onChange={handleRoleChange}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                    />
                    <label
                        htmlFor="intern"
                        className=" py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                    >
                      Thử việc
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={submitHandler}
          >
            Tạo tài khoản
          </button>
        </form>
      </div>
    </>
  );
}

export default AddUser;
