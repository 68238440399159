import axios from "axios";
import { API } from "../utils/apiURL";

export const createdTeleSales = async (data, access_token) => {
  const res = await axios.post(`${API}/real-estate`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getTeleSales = async (access_token) => {
  const res = await axios.get(`${API}/telesales`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getTeleSalesRent = async (access_token) => {
  const res = await axios.get(`${API}/telesales`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const updateTeleSales = async (id, data, access_token) => {
  const res = await axios.patch(`${API}/telesales/real-estate/${id}`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const updateRequire = async (id, access_token) => {
  const res = await axios.patch(
    `${API}/telesales/real-estate/require/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
  return res.data;
};
export const cancelRequire = async (id, access_token) => {
  const res = await axios.patch(`${API}/sales/require/${id}/cancel`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const callAssignment = async (data, access_token) => {
  const res = await axios.post(`${API}/telesales/call-assignment`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getCallAssignment = async (page, access_token,user,created_at) => {
  const res = await axios.get(`${API}/telesales/call-assignment?page=${page}&user=${user}&createdAt=${created_at}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res.data;
};

export const deleteCallAssignment = async (id, access_token) => {
  try {
    const res = await axios.delete(`${API}/telesales/call-assignment/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  }catch (error) {
    console.log(error)
  }
}
// export const getAllUser = async () => {
//   const res = await axios.get(`${API}/users`);
//   return res.data;
// };D
// export const getUserById = async (id) => {
//   const res = await axios.get(`${API}/users/${id}`);
//   return res.data;
// };

// export const updateUser = async (data) => {
//   //   const headers = {
//   //     Authorization: `Bearer ${data.access_token}`,
//   //   };
//   const userId = data.id;
//   delete data.id;
//   const res = await axios.patch(`${API}/users/${userId}`, data);
//   return res.data;
// };

// export const deleteUserById = async (id) => {
//   const res = await axios.delete(`${API}/users/${id}`);
//   return res.data;
// };
// export const updateAddress = async (data) => {
//   const headers = {
//     Authorization: `Bearer ${data.access_token}`,
//   };
//   const userId = data.id;
//   delete data.id;
//   const res = await axios.post(
//     `${API}/api/v1/user/updateAdress/user/${userId}`,
//     data,
//     {
//       headers,
//     }
//   );

//   return res.data;
// };
