import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getSidebarStatus, handleSidebar, handleCloseSidebar} from "../../store/sidebarReducer";
import {resetUser} from "../../features/userSlice/userSlice";
import {resetProject} from "../../features/projectSlice/projectSlice";
import logo from "./CNT-Logo-Vien3.png"

function Sidebar() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    const history = useNavigate();
    const isSidebarOn = useSelector(getSidebarStatus);
    const userLogin = useSelector((state) => state.user);
    const {roles} = userLogin;
    let userRole = userLogin?.roles;
    if (roles) {
        userRole = roles[0];
    }
    const roleTabUser = ["super_admin"];
    const roleTabLand = ["super_admin", "admin", "manager"];
    const roleTabSale = ["super_admin", "admin", "manager", "sale"];
    const roleTabTele = ["super_admin", "admin", "telesale"];
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownTelesale, setIsDropdownTelesale] = useState(false);
    const [isDropdownProject, setIsDropdownProject] = useState(false);
    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    //   const dispatch = useDispatch();
    const handleLogout = () => {
        dispatch(resetUser());
        dispatch(resetProject())
        localStorage.removeItem("access_token");
        history("/login")
    };

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener("resize", handleResize);
        if(windowWidth < 845) {
            dispatch(handleCloseSidebar())
        }
        return () => window.removeEventListener("resize", handleResize);
    }, [windowWidth])

    return (
        <>
            <div
                className={
                    isSidebarOn
                        ? "flex justify-between items-center border-b-2 pb-1 xs:ml-64"
                        : "flex justify-between items-center border-b-2 pb-1 "
                }
            >
                <button
                    type="button"
                    onClick={() => dispatch(handleSidebar())}
                    className="inline-flex items-center justify-between p-2 mt-2 md:ms-3 ms-4 text-sm text-gray-900 rounded-lg hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                >
                    <span className="sr-only">Open sidebar</span>
                    <svg
                        className="w-6 h-6"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            clip-rule="evenodd"
                            fill-rule="evenodd"
                            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                        ></path>
                    </svg>
                </button>

                <div className="relative right-3">
                    <button
                        onClick={handleDropdownToggle}
                        className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300"
                        type="button"
                    >
                        <span className="sr-only">Open user menu</span>
                        <svg className="w-8 h-8 text-gray-800 dark:text-white" aria-hidden="true"
                             xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                            <path
                                d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z"/>
                        </svg>
                    </button>

                    <div
                        id="dropdownAvatar"
                        className={
                            isDropdownOpen
                                ? "z-10 absolute right-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-40 dark:bg-gray-700 dark:divide-gray-600"
                                : "hidden"
                        }
                    >
                        <ul
                            className="py-2 text-sm text-gray-700 text-start dark:text-gray-400"
                            aria-labelledby="dropdownLargeButton"
                        >
                            <li>
                                <Link
                                    to="/user/update-profile"
                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    Cập nhật thông tin
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to="/user/change-password"
                                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                                >
                                    Đổi mật khẩu
                                </Link>
                            </li>
                        </ul>
                        <div className="py-1 text-start">
                            <a
                                href="#"
                                onClick={handleLogout}
                                className="block px-4 py-2 text-sm text-red-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white"
                            >
                                Đăng xuất
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <aside
                className={
                    isSidebarOn
                        ? "fixed top-0 left-0 z-40 w-64 h-screen transition-transform sm:translate-x-0"
                        : "hidden"
                }
            >
                <div className="h-full px-3 py-4 overflow-y-auto bg-[#0455A6]">
                    <ul className="space-y-2 font-medium">
                        <li className="bg-gradient-to-r from-[#E1DE6D] to-[#502F18] rounded-lg ">
                            <Link
                                to="/"
                                className="flex items-center p-3 text-white font-semibold rounded-lg hover:bg-[#E1DE6D] group"
                            >
                                <img
                                    // src="https://cntland.com.vn/wp-content/uploads/2022/11/CNT-Logo-Vien3.png"
                                    src={logo}
                                    className="mx-auto h-16 me-3 sm:h-7"
                                    alt="CNT LAND Logo"
                                />

                                {/*<span className="ms-3 text-sm font-bold uppercase text-gray-700">CNT LAND MANAGER</span>*/}
                            </Link>
                        </li>

                        {
                            <>
                                <li>
                                    <Link
                                        to="/"
                                        className={
                                            userRole === "super_admin"
                                                ? "flex items-center p-2 text-white rounded-lg hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-700 group"
                                                : "hidden"
                                        }
                                    >
                                        <svg
                                            className="w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 22 21"
                                        >
                                            <path
                                                d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z"/>
                                            <path
                                                d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z"/>
                                        </svg>
                                        <span className="ms-3 whitespace-nowrap">Thống kê</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/user"
                                        className={
                                            roleTabUser.includes(userRole)
                                                ? "flex items-center p-2 text-white rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                                : "hidden"
                                        }
                                    >
                                        <svg
                                            className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="currentColor"
                                            viewBox="0 0 20 18"
                                        >
                                            <path
                                                d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z"/>
                                        </svg>
                                        <span className="ms-3 whitespace-nowrap">Người dùng</span>
                                    </Link>
                                </li>
                            </>
                        }
                        <li>
                            <Link
                                to="/land"
                                className={
                                    roleTabLand.includes(userRole)
                                        ? "flex items-center p-2 text-white rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                        : "hidden"
                                }
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M4 15V9m4 6V9m4 6V9m4 6V9M2 16h16M1 19h18M2 7v1h16V7l-8-6-8 6Z"
                                    />
                                </svg>
                                <span className="ms-3 whitespace-nowrap">Bất động sản</span>
                            </Link>
                        </li>
                        <li>
                            <Link
                                to="/sale"
                                className={
                                    userRole !== "telesale"
                                        ? "flex items-center p-2 text-white rounded-lg hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 group"
                                        : "hidden"
                                }
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                >
                                    <path
                                        d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z"/>
                                </svg>
                                <span className="ms-3 whitespace-nowrap">Sales</span>
                            </Link>
                        </li>
                        <li className={roleTabTele.includes(userRole) ? "" : "hidden"}>
                            <button
                                type="button"
                                onClick={() => setIsDropdownTelesale(!isDropdownTelesale)}
                                className={
                                    userRole !== "sale"
                                        ? "flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:text-gray-900 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        : "hidden"
                                }
                                aria-controls="telesale-dropdown"
                                data-collapse-toggle="telesale-dropdown"
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                >
                                    <path
                                        d="M18 13.446a3.02 3.02 0 0 0-.946-1.985l-1.4-1.4a3.054 3.054 0 0 0-4.218 0l-.7.7a.983.983 0 0 1-1.39 0l-2.1-2.1a.983.983 0 0 1 0-1.389l.7-.7a2.98 2.98 0 0 0 0-4.217l-1.4-1.4a2.824 2.824 0 0 0-4.218 0c-3.619 3.619-3 8.229 1.752 12.979C6.785 16.639 9.45 18 11.912 18a7.175 7.175 0 0 0 5.139-2.325A2.9 2.9 0 0 0 18 13.446Z"/>
                                </svg>
                                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                  Telesale
                </span>
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>
                            <ul
                                id="telesale-dropdown"
                                className={isDropdownTelesale ? "py-2 space-y-2" : "hidden"}
                            >
                                <li>
                                    <a
                                        href="/telesale/update-land"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 hover:text-gray-900 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        }
                                    >
                                        Gọi chủ nhà
                                    </a>
                                </li>
                                <li>
                                    <Link
                                        to="/telesale/call-assignment"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 hover:text-gray-900 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        }
                                    >
                                        Phân công gọi
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/telesale/callback"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 hover:text-gray-900 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                                        }
                                    >
                                        Gọi lại sau
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <Link
                                to="/gallery"
                                className="flex items-center p-2 text-white rounded-lg dark:text-white hover:text-gray-900 hover:bg-gray-100 dark:hover:bg-gray-700 group"
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400  group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 18"
                                >
                                    <path
                                        d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2ZM10.5 6a1.5 1.5 0 1 1 0 2.999A1.5 1.5 0 0 1 10.5 6Zm2.221 10.515a1 1 0 0 1-.858.485h-8a1 1 0 0 1-.9-1.43L5.6 10.039a.978.978 0 0 1 .936-.57 1 1 0 0 1 .9.632l1.181 2.981.541-1a.945.945 0 0 1 .883-.522 1 1 0 0 1 .879.529l1.832 3.438a1 1 0 0 1-.031.988Z"/>
                                    <path
                                        d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z"/>
                                </svg>
                                <span className="ms-3 whitespace-nowrap">Đăng ảnh</span>
                            </Link>
                        </li>
                        <li className={userRole == "super_admin" ? "" : "hidden"}>
                            <button
                                type="button"
                                onClick={() => setIsDropdownProject(!isDropdownProject)}
                                className={
                                    userRole = "super_admin"
                                        ? "flex items-center w-full p-2 text-base text-white transition duration-75 rounded-lg group hover:bg-gray-100 hover:text-gray-900"
                                        : "hidden"
                                }
                                aria-controls="project-dropdown"
                                data-collapse-toggle="project-dropdown"
                            >
                                <svg
                                    className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>
                                </svg>
                                <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">
                            Dự án
                            </span>
                                <svg
                                    className="w-3 h-3"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 10 6"
                                >
                                    <path
                                        stroke="currentColor"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        stroke-width="2"
                                        d="m1 1 4 4 4-4"
                                    />
                                </svg>
                            </button>
                            <ul
                                id="project-dropdown"
                                className={isDropdownProject ? "py-2 space-y-2" : "hidden"}
                            >
                                <li>
                                    <Link
                                        to="/project"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-gray-900"
                                        }
                                    >
                                        Dự án
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/group"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-gray-900 dark:text-white dark:hover:bg-gray-700"
                                        }
                                    >
                                        Phân khu
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/building"
                                        className={
                                            "flex items-center w-full p-2 text-white transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 hover:text-gray-900 dark:text-white dark:hover:bg-gray-700"
                                        }
                                    >
                                        Tòa
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        {/*<li>*/}
                        {/*    <Link*/}
                        {/*        to="/project"*/}
                        {/*        className={*/}
                        {/*            userRole === "super_admin"*/}
                        {/*                ? "flex items-center p-2 text-white rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"*/}
                        {/*                : "hidden"*/}
                        {/*        }*/}
                        {/*    >*/}
                        {/*        <svg*/}
                        {/*            className="flex-shrink-0 w-5 h-5 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-gray-900"*/}
                        {/*            aria-hidden="true"*/}
                        {/*            xmlns="http://www.w3.org/2000/svg"*/}
                        {/*            fill="currentColor"*/}
                        {/*            viewBox="0 0 20 20"*/}
                        {/*        >*/}
                        {/*            <path*/}
                        {/*                d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z"/>*/}
                        {/*        </svg>*/}
                        {/*        <span className="ms-3 whitespace-nowrap">Dự án</span>*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </aside>

        </>
    );
}

export default Sidebar
;
