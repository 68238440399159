import axios from "axios";
import {API} from "../utils/apiURL";

export const getAll = async (id, access_token) => {
    let url = `${API}/group`;
    if (id) {
        url += `?project_id=${id}`;
    }
    const res = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};

export const getGroupById = async (id, access_token) => {
    const res = await axios.get(`${API}/group/${id}`,{
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
}

export const getAllByProjectName = async (name) => {
    const res = await axios.get(`${API}/group?project_id.project=${name}`);
    return res.data;
};

export const getGroupByProjectId = async (id, access_token) => {
    const res = await axios.get(`${API}/group?project_id=${id}`,{
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
}

export const createGroup = async (data, access_token) => {
    const res = await axios.post(`${API}/group`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res;
};

export const editGroup = async (id, data, access_token) => {
    const res = await axios.patch(`${API}/group/${id}`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res;
};

export const deleteGroupById = async (id, access_token) => {
    const res = await axios.delete(`${API}/group/${id}`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res;
}
