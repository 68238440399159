import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSidebarOn: true,
};

const sidebarSlice = createSlice({
    name: "sidebar",
    initialState,
    reducers: {
        handleSidebar: (state) => {
            state.isSidebarOn = !state.isSidebarOn;
        },
        handleCloseSidebar: (state) => {
            state.isSidebarOn = false;
        }
    },
});

export const { handleSidebar, handleCloseSidebar } = sidebarSlice.actions;
export const getSidebarStatus = (state) => state.sidebar.isSidebarOn;
export default sidebarSlice.reducer;