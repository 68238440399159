import React, { useEffect } from "react";
import { IconButton, Typography } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useSearchParams } from "react-router-dom";

function Pagination(props) {
  const { data, active = 1, setActive = (active) => {} } = props;
  console.log("🚀 ~ Pagination ~ data:", data)
  const totalPage = data.totalPage ? data.totalPage : 1;

  const [searchParams, setSearchParams] = useSearchParams();
  const next = () => {
    if (active === totalPage) return;
    setActive(active + 1);
    setSearchParams({ page: active + 1 });
  };

  const prev = () => {
    if (active === 1) return;
    setActive(active - 1);
    setSearchParams({ page: active - 1 });
  };

  return (
    <div className="flex items-center gap-8">
      <IconButton
        size="sm"
        variant="outlined"
        onClick={prev}
        disabled={active === 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
      <Typography color="gray" className="font-normal">
        Page <strong className="text-gray-900">{active}</strong> of{" "}
        <strong className="text-gray-900">{totalPage}</strong>
      </Typography>
      <IconButton
        size="sm"
        variant="outlined"
        onClick={next}
        disabled={active === totalPage}
      >
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </IconButton>
    </div>
  );
}

export default Pagination;
