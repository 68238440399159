import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  type: "",
};

const statusSlice = createSlice({
  name: "status",
  initialState,
  reducers: {
    updateStatus: (state, action) => {
      const { type } = action.payload;
      state.type = type;
    },
  },
});

export const { updateStatus } = statusSlice.actions

export default statusSlice.reducer
