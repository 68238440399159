import React, { useEffect } from "react";
import Pagination from "../Pagination/Pagination";
import { getAllRent } from "../../functions/saleFunction";
import { Link, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import {DateNew, DateTime} from "../../functions/CustomeDate";
import { customHouse } from "../../functions/House";
import { customInterior } from "../../functions/Interior";
import { useSelector } from "react-redux";
import { mahoaTang } from "../../functions/maHoaCode";
import * as WareHouse from "../../services/TeleService";
import { useMutationHooks } from "../../hooks/useMutationHook";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";
import { formatMoney } from "../../functions/formatMonney";
import { TESelect } from "tw-elements-react";
import * as ProjectService from "../../services/ProjectService";
import * as GroupService from "../../services/GroupService";
import * as BuildingService from "../../services/BuildingService";
import { notiNewRealEstate } from "../../functions/notiNewRealEstate";
import * as XLSX from "xlsx";
import axios from "axios";

function WarehouseRent() {
  const allowInfoLandlord = ["super_admin", "admin", "manager"];
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const userLogin = useSelector((state) => state.user);
  const { roles, id: userId, access_token } = userLogin;
  const [warehouse, setWarehouse] = React.useState([]);
  const [code, setCode] = React.useState("");
  const [project, setProject] = React.useState("");
  const [group, setGroup] = React.useState("");
  const [building, setBuilding] = React.useState("");
  const [interior, setInterior] = React.useState("");
  const [kindOf, setKindOf] = React.useState("");
  const [direction, setDirection] = React.useState("");
  const [bedroomNumber, setBedroomNumber] = React.useState("");
  const [room, setRoom] = React.useState("");
  const [active, setActive] = React.useState(1);
  const [dataProjects, setDataProjects] = React.useState([]);
  const [dataGroups, setDataGroups] = React.useState([]);
  const [dataBuilding, setDataBuilding] = React.useState([]);
  const [price_sale, setPriceSale] = React.useState("");

  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { isLoading, data, isError, error } = useQuery(
    [
      "WarehouseRent",
      page,
      code,
      project,
      group,
      building,
      interior,
      kindOf,
      direction,
      bedroomNumber,
      room,
      "",
      "",
      price_sale,
    ],
    () =>
      getAllRent(
        page,
        code,
        project,
        group,
        building,
        interior,
        kindOf,
        direction,
        bedroomNumber,
        room,
        "",
        "",
        access_token,
        price_sale
      )
  );
  const exportToExcel = async (
    project,
    group,
    building,
    interior,
    kindOf,
    direction,
    bedroomNumber,
    room
  ) => {
    try {
      const response = await axios.get(
        `https://api.cntlandmanager.com/api/sales/warehouse-rented/all?project=${project}&group=${group}&building=${building}&interior=${interior}&kind_of=${kindOf}&direction=${direction}&bedroom_number=${bedroomNumber}&room=${room}`
      );
      const result = await response.data;
      if (result.data) {
        const dataArray = Object.values(result.data);

        const data = dataArray.map((item, index) => [
          index + 1,
          item.code,
          item?.building?.building_name + item?.floor + item?.room,
          DateTime(item.updatedAt),
          formatMoney(item.price_sale),
          formatMoney(item.price_rented),
          `${item.project?.project} - ${item.building?.building_name}\n${
            item.acreage
          }m² - ${customHouse(item.kind_of)} - ${item.direction} - ${
            item.bedroom_number
          }\n${item.note}\n${customInterior(item.interior)}`,
          item.landlord,
          item.phone_number_1,
        ]);

        const wsData = [
          [
            "STT",
            "Mã tin",
            "Căn hộ",
            "Thời gian cập nhật",
            "Giá bán",
            "Giá cho thuê",
            "Thông tin BĐS",
            "Tên chủ nhà",
            "Số điện thoại chủ nhà",
          ],
          ...data,
        ];

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "KhoChoThue.xlsx");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  React.useEffect(() => {
    if (data) {
      setWarehouse(data);
    }
  }, [data]);
  const mutationUpdateLand = useMutationHooks(async (data) => {
    const { id } = data;
    const res = await WareHouse.updateRequire(id, access_token);
    return res;
  });
  const submitHandler = async (id) => {
    // event.preventDefault();
    await mutationUpdateLand.mutate({
      id,
    });
  };

  const {
    isLoading: isLoadingUpdateLand,
    data: dataUpdateLand,
    isError: isErrorUpdateLand,
    error: errorUpdateLand,
    isSuccess: isSuccessUpdateLand,
  } = mutationUpdateLand;

  const mutationCancelLand = useMutationHooks(async (data) => {
    const { id } = data;
    const res = await WareHouse.cancelRequire(id);
    return res;
  });
  const cancleHandler = async (id) => {
    await mutationCancelLand.mutate({ id });
  };
  const { data: projects } = useQuery(["Project", access_token], () =>
    ProjectService.getGroupProject(access_token)
  );
  const {
    isLoading: isLoadingCancelLand,
    data: dataCancelLand,
    isError: isErrorCancelLand,
    error: errorCancelLand,
    isSuccess: isSuccessCancelLand,
  } = mutationCancelLand;

  useEffect(() => {
    if (isErrorUpdateLand && errorUpdateLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Yêu cầu không thành công", Toastobjects);
      }
    } else if (isSuccessUpdateLand && !errorUpdateLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Yêu cầu thành công", Toastobjects);
      }
    }
  }, [isErrorUpdateLand, isSuccessUpdateLand, errorUpdateLand]);
  const mutationGetGroupByProjectId = useMutationHooks((data) => {
    const res = GroupService.getGroupByProjectId(data, access_token);
    return res;
  });
  const mutationGetBuildingByGroupId = useMutationHooks((id) => {
    const res = BuildingService.getAllBuilding(project, id, access_token);
    return res;
  });
  const {
    error: errorGetGroupByProjectId,
    isSuccess: isSuccessGetGroupByProjectId,
    isError: isErrorGetGroupByProjectId,
    data: dataGetGroupByProjectId,
  } = mutationGetGroupByProjectId;
  const {
    error: errorGetBuildingByGroupId,
    isSuccess: isSuccessGetBuildingByGroupId,
    isError: isErrorGetBuildingByGroupId,
    data: dataGetBuildingByGroupId,
  } = mutationGetBuildingByGroupId;
  const handleChangeProject = async (value) => {
    setProject(value);
    await mutationGetGroupByProjectId.mutate(value);
  };
  const handleChangeGroup = async (value) => {
    setGroup(value);
    await mutationGetBuildingByGroupId.mutate(value);
  };
  const handleChangeBuilding = (value) => {
    setBuilding(value);
  };

  useEffect(() => {
    if (isErrorCancelLand && errorCancelLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Hủy yêu cầu không thành công",
          Toastobjects
        );
      }
    } else if (isSuccessCancelLand && !errorCancelLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Hủy yêu cầu thành công", Toastobjects);
      }
    }
  }, [isErrorCancelLand, isSuccessCancelLand, errorCancelLand]);
  useEffect(() => {
    if (projects?.data && projects.data.length > 0) {
      const newDataProjects = projects.data.map((item) => ({
        value: item._id,
        text: item.project,
      }));
      newDataProjects.unshift({ value: "", text: "None" });
      setDataProjects(newDataProjects);
    }
  }, [projects]);
  //Cap nhat du lieu Group theo Project Id
  useEffect(() => {
    if (
      dataGetGroupByProjectId?.data &&
      dataGetGroupByProjectId.data.length > 0
    ) {
      const newDataGroups = dataGetGroupByProjectId.data.map((item) => ({
        value: item._id,
        text: item.group_name,
      }));
      newDataGroups.unshift({ value: "", text: "None" });
      setDataGroups(newDataGroups);
    }
  }, [dataGetGroupByProjectId]);

  //Cap nhat du lieu Building theo Group Id
  useEffect(() => {
    setDataBuilding([]);
    if (
      dataGetBuildingByGroupId?.data &&
      dataGetBuildingByGroupId.data.length > 0
    ) {
      const newDataBuilding = dataGetBuildingByGroupId.data.map((item) => ({
        value: item._id,
        text: item.building_name,
      }));
      newDataBuilding.unshift({ value: "", text: "None" });
      setDataBuilding(newDataBuilding);
    }
  }, [dataGetBuildingByGroupId]);

  return (
    <>
      <Toast />
      {/* <Filter setWarehouse={setWarehouse} type={"rented"} /> */}
      <div className="grid sm:grid-cols-4 md:grid-cols-6 gap-1 mb-2 ">
        <div>
          <input
            type="text"
            id="code"
            onChange={(e) => setCode(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Mã tin"
          />
        </div>
        <div>
          <TESelect
            data={dataProjects}
            search
            onClick={(e) => setGroup("")}
            onValueChange={(e) => handleChangeProject(e.value)}
            placeholder={"Dự án"}
          />
        </div>
        <div>
          <TESelect
            data={dataGroups}
            search
            // onClick={(e) => setBuilding("")}
            onValueChange={(e) => handleChangeGroup(e.value)}
            placeholder={"Phân khu"}
          />
        </div>
        <div className="">
          <TESelect
            data={dataBuilding}
            search
            onValueChange={(e) => handleChangeBuilding(e.value)}
            placeholder={"Tòa"}
          />
        </div>
        <div className="">
          <select
            id="interior"
            onChange={(e) => setInterior(e.target.value)}
            value={interior}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" selected>
              Chọn nội thất
            </option>
            <option value="1">Nội thất cơ bản</option>
            <option value="2">Full nội thất</option>
            <option value="3">Nguyên bản chủ đầu tư</option>
          </select>
        </div>
        <div className="">
          <select
            id="kind_of"
            onChange={(e) => setKindOf(e.target.value)}
            value={kindOf}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="" selected>
              Chọn loại bất động sản
            </option>
            <option value="1">Căn hộ chung cư</option>
            <option value="2">Căn hộ penthouse</option>
            <option value="3">Căn hộ Duplex</option>
            <option value="4">Căn hộ Studio</option>
            <option value="5">OfficeTel</option>
            <option value="6">Biệt thự liền kề</option>
            <option value="7">ShopHouse</option>
            <option value="8">Căn hộ đập thông</option>
          </select>
        </div>
        <div className="">
          <input
            type="text"
            id="direction"
            name="direction"
            value={direction}
            className="xs:w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Hướng ban công"
            onChange={(e) => setDirection(e.target.value)}
          />
        </div>
        <div className="">
          <input
            type="text"
            id="room_number"
            name="room_number"
            value={bedroomNumber}
            className="xs:w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Số phòng ngủ"
            onChange={(e) => setBedroomNumber(e.target.value)}
          />
        </div>
        <div className="">
          <select
            id="sort"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={(e) => setPriceSale(e.target.value)}
          >
            <option selected>Chọn sắp xếp</option>
            <option value="-1">Giá cho thuê giảm dần</option>
            <option value="1">Giá cho thuê tăng dần</option>
          </select>
        </div>
        <div>
          <input
            type="text"
            id="room"
            onChange={(e) => setRoom(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Trục căn"
          />
        </div>
        <div>
          {(roles[0] === "super_admin" || roles[0] === "admin") && (
            <button
              className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 
            font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={() =>
                exportToExcel(
                  project,
                  group,
                  building,
                  interior,
                  kindOf,
                  direction,
                  bedroomNumber,
                  room
                )
              }
            >
              Xuất file
            </button>
          )}
        </div>
        {/*<div>*/}
        {/*  <input*/}
        {/*      type="date"*/}
        {/*      id="date_start"*/}
        {/*      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
        {/*      placeholder="Gọi từ ngày"*/}
        {/*      onFocus={(e) => (e.target.type = "date")}*/}
        {/*      onBlur={(e) => (e.target.type = "text")}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <input*/}
        {/*      type="date"*/}
        {/*      id="date_end"*/}
        {/*      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
        {/*      placeholder="Đến ngày"*/}
        {/*      onFocus={(e) => (e.target.type = "date")}*/}
        {/*      onBlur={(e) => (e.target.type = "text")}*/}
        {/*  />*/}
        {/*</div>*/}
      </div>
      {/* <button
          onClick={handleFind}
          className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Tìm kiếm
        </button> */}

      {isLoading && !warehouse?.length ? (
        <div>Loading</div>
      ) : (
        <Pagination data={warehouse} active={active} setActive={setActive} />
      )}

      <h1 className="mt-3 text-start text-2xl font-semibold">Data nguồn</h1>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-white uppercase bg-blue-500">
            <tr>
              <th scope="col" className="px-2 py-3 text-center">
                STT
              </th>
              <th scope="col" className="px-2 py-3">
                Mã tin
              </th>
              <th
                scope="col"
                className={roles[0] !== "intern" ? "px-2 py-3" : "hidden"}
              >
                Căn hộ
              </th>
              <th scope="col" className="px-2 py-3">
                Thời gian cập nhật
              </th>
              <th scope="col" className="px-2 py-3">
                Giá bán
              </th>
              <th scope="col" className="px-2 py-3">
                Giá cho thuê
              </th>
              <th scope="col" className="px-2 py-3">
                Thông tin BĐS
              </th>
              <th scope="col" className="px-2 py-3">
                Tên chủ nhà
              </th>
              <th scope="col" className="px-2 py-3">
                SĐT chủ nhà
              </th>
              <th scope="col" className="px-2 py-3">
                Ngày tạo
              </th>
              <th scope="col" className="px-2 py-3">
                <span className="sr-only">Yêu cầu SĐT</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div>Loading</div>
            ) : (
              warehouse?.data?.map((item, index) => (
                <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                  <td className="text-center px-2 py-4">{index + 1}</td>
                  <td className="px-2 py-4">{item.code}</td>
                  {item.statusImg ? (
                    <td
                      className={
                        roles[0] !== "intern"
                          ? "px-2 py-4 bg-[#00ef3f]"
                          : "hidden"
                      }
                      // onClick={handleRedirectImg(item.code)}
                    >
                      <Link
                        to={`/gallery?code=${item.code}`}
                        target="_blank"
                        className="hover:underline"
                      >
                        {(roles[0] === "sale" &&
                          item.isApprove &&
                          item.isRequire &&
                          item?.user_required == userId) ||
                        roles[0] === "super_admin" ||
                        roles[0] === "admin" ||
                        roles[0] === "manager"
                          ? item.apartment
                          : item?.building?.building_name +
                            mahoaTang(item?.floor) +
                            item?.room}
                      </Link>
                    </td>
                  ) : (
                    <td
                      className={roles[0] !== "intern" ? "px-2 py-4" : "hidden"}
                    >
                      {(roles[0] === "sale" &&
                        item.isApprove &&
                        item.isRequire &&
                        item?.user_required == userId) ||
                      roles[0] === "super_admin" ||
                      roles[0] === "admin" ||
                      roles[0] === "manager"
                        ? item.apartment
                        : item?.building?.building_name +
                          mahoaTang(item?.floor) +
                          item?.room}
                    </td>
                  )}
                  <td className="px-2 py-4">{DateTime(item.updatedAt)}</td>
                  <td className="px-2 py-4">{formatMoney(item.price_sale)}</td>
                  <td className="px-2 py-4">
                    {formatMoney(item.price_rented)}
                  </td>
                  <td
                    scope="row"
                    className={
                      notiNewRealEstate(item.createdAt)
                        ? `px-2 py-4 bg-yellow-100`
                        : "px-2 py-4"
                    }
                  >
                    <p className="flex items-center gap-1">
                      <span>
                        <svg
                          className="w-3 h-3 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 18 18"
                        >
                          <path d="M17 16h-1V2a1 1 0 1 0 0-2H2a1 1 0 0 0 0 2v14H1a1 1 0 0 0 0 2h16a1 1 0 0 0 0-2ZM5 4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V4Zm0 5V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1Zm6 7H7v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3Zm2-7a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Zm0-4a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1Z" />
                        </svg>
                      </span>
                      <span>
                        {item.project?.project} - {item.building?.building_name}
                      </span>
                    </p>
                    <p className="flex items-center gap-1">
                      <span>
                        <svg
                          className="w-3 h-3 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 20"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M3 5v10M3 5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm12 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0V6a3 3 0 0 0-3-3H9m1.5-2-2 2 2 2"
                          />
                        </svg>
                      </span>
                      <span>
                        {item.acreage}m² - {customHouse(item.kind_of)} -{" "}
                        {item.direction} - {item.bedroom_number}
                      </span>
                    </p>
                    <p className="flex items-baseline gap-1">
                      <span>
                        <svg
                          className="w-3 h-3 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="m13.835 7.578-.005.007-7.137 7.137 2.139 2.138 7.143-7.142-2.14-2.14Zm-10.696 3.59 2.139 2.14 7.138-7.137.007-.005-2.141-2.141-7.143 7.143Zm1.433 4.261L2 12.852.051 18.684a1 1 0 0 0 1.265 1.264L7.147 18l-2.575-2.571Zm14.249-14.25a4.03 4.03 0 0 0-5.693 0L11.7 2.611 17.389 8.3l1.432-1.432a4.029 4.029 0 0 0 0-5.689Z" />
                        </svg>
                      </span>
                      <div
                        dangerouslySetInnerHTML={{ __html: item?.note }}
                      ></div>
                    </p>
                    <p className="flex items-baseline gap-1">
                      <span>
                        <svg
                          className="w-3 h-3 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path d="M17.876.517A1 1 0 0 0 17 0H3a1 1 0 0 0-.871.508C1.63 1.393 0 5.385 0 6.75a3.236 3.236 0 0 0 1 2.336V19a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-6h4v6a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9.044a3.242 3.242 0 0 0 1-2.294c0-1.283-1.626-5.33-2.124-6.233ZM15.5 14.7a.8.8 0 0 1-.8.8h-2.4a.8.8 0 0 1-.8-.8v-2.4a.8.8 0 0 1 .8-.8h2.4a.8.8 0 0 1 .8.8v2.4ZM16.75 8a1.252 1.252 0 0 1-1.25-1.25 1 1 0 0 0-2 0 1.25 1.25 0 0 1-2.5 0 1 1 0 0 0-2 0 1.25 1.25 0 0 1-2.5 0 1 1 0 0 0-2 0A1.252 1.252 0 0 1 3.25 8 1.266 1.266 0 0 1 2 6.75C2.306 5.1 2.841 3.501 3.591 2H16.4A19.015 19.015 0 0 1 18 6.75 1.337 1.337 0 0 1 16.75 8Z" />
                        </svg>
                      </span>
                      <span>Nội thất: {customInterior(item.interior)}</span>
                    </p>
                  </td>
                  <td className={"px-2 py-4"}>
                    {(item.isApprove === true &&
                      item.user_required == userId) ||
                    allowInfoLandlord.includes(roles[0])
                      ? item.landlord
                      : ""}
                  </td>
                  <td className={"px-2 py-4"}>
                    {(item.isApprove === true &&
                      item.user_required == userId) ||
                    allowInfoLandlord.includes(roles[0])
                      ? item.phone_number_1
                      : ""}
                  </td>
                  <td className={"px-2 py-4"}>
                    {DateNew(item.createdAt)}
                  </td>
                  <td
                    className={
                      roles[0] != "sale" ? "hidden" : "px-2 py-4 text-right"
                    }
                  >
                    <button
                      onClick={() => submitHandler(item._id)}
                      className={
                        roles[0] == "sale" && item.isRequire === false
                          ? "font-medium text-blue-600 hover:underline"
                          : "hidden"
                      }
                    >
                      Yêu cầu SĐT
                    </button>
                    <button
                      onClick={() => cancleHandler(item._id)}
                      className={
                        item.user_required == userId
                          ? "font-medium text-red-600 hover:underline"
                          : "hidden"
                      }
                    >
                      Hủy cầu SĐT
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>

      {isLoading && !warehouse?.length ? (
          <div>Loading</div>
      ) : (
          <div className="mt-2"><Pagination data={warehouse} active={active} setActive={setActive} /></div>
      )}
    </>
  );
}

export default WarehouseRent;
