import React from "react";
import { useQuery } from "react-query";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Toast from "../../../components/Toast/Toast";
import * as TeleSaleService from "../../../services/TeleService";
import * as CallbackService from "../../../services/CallbackService";
import { useMutationHooks } from "../../../hooks/useMutationHook";
import { useSelector } from "react-redux";
import { formatMoney } from "../../../functions/formatMonney";
import { DateNew } from "../../../functions/CustomeDate";
import JsSIP from "jssip";
import { strToCapitalize } from "../../../functions/strToCapitalize";
import { useMutation } from 'react-query';
import "react-quill/dist/quill.snow.css";
import { EditorComponent } from "../../Editor/EditorComponent";
import {useNavigate, useParams} from "react-router-dom";
function UpdateLand() {
  const history = useNavigate();
  const { id: callbackId } = useParams();
  const [id, setID] = useState("");
  const [landlord, setLandlord] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
  const [acreage, setAcreage] = useState("");
  const [interior, setInterior] = useState("");
  const [phone, setPhone] = useState(null);
  const [call, setCall] = useState(null);
  const [statusCallio, setStatusCallio] = useState("");
  const [priceSale, setPriceSale] = useState("");
  const [priceRent, setPriceRent] = useState("");
  const [bedroomNumber, setBedRoomNumber] = useState("");
  const [direction, setDirection] = useState("");
  const [note, setNote] = useState("");
  console.log("🚀 ~ UpdateLand ~ note:", note)
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [phoneChoose, setPhoneChoose] = useState("");
  const userLogin = useSelector((state) => state.user);
  const { id: userId, access_token } = userLogin;
  const [isLoading1, setLoading1] = React.useState(true);
  const [isLoading2, setLoading2] = React.useState(true);
  const [dataCall, setDataTemp] = React.useState([]);
  const [dataCall1, setDataTemp1] = React.useState([]);
  const projectList = useSelector((state) => state.project);
  const { listProject } = projectList;


  const handleChoosePhone = (phoneNumber) => {
    if (phoneChoose === phoneNumber) {
      // If the checkbox is already checked, uncheck it
      setPhoneChoose("");
    } else {
      // Otherwise, check the checkbox
      setPhoneChoose(phoneNumber);
    }
  };

  const handleCallLandlord = () => {
    if (phoneChoose === "") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Vui lòng chọn số điện thoại để gọi",
          Toastobjects
        );
      }
    }
    if (phoneChoose === "phone_number_1" && phoneNumberOne) {
      // window.open(`tel:${phoneNumberOne}`);
      handleCall(phoneNumberOne);
    } else if (phoneChoose === "phone_number_2" && phoneNumberTwo) {
      // window.open(`tel:${phoneNumberTwo}`);
      handleCall(phoneNumberTwo);
    }
  };
  const handleEndCall = () => {
    if (call) {
      call.terminate();
      setCall(null);
    }
  };
  const host = process.env.REACT_APP_HOST;
  const ext = process.env.REACT_APP_EXT;
  const password = process.env.REACT_APP_PASSWORD;
  const contact_uri =
    "sip:" +
    ext +
    "@" +
    Math.random().toString(36).substring(2, 15) +
    ".invalid;transport=ws";
  let callOptions = {
    mediaConstraints: {
      audio: true,
      video: false,
    },
    pcConfig: {
      iceServers: [
        {
          urls: ["stun:stun.l.google.com:19302"],
        },
      ],
    },
  };
  useEffect(() => {
    const initPhone = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });

        const newPhone = new JsSIP.UA({
          sockets: [new JsSIP.WebSocketInterface(`wss://${host}:7443`)],
          uri: `sip:${ext}@${host}`,
          realm: host,
          password: password,
          contact_uri: contact_uri,
        });

        newPhone.on("connected", () => {
          setStatusCallio("Điện thoại sẵn sàng");
        });

        newPhone.on("disconnected", () => {
          setStatusCallio("Mất kết nối");
        });

        newPhone.start();

        newPhone.on("newRTCSession", (data) => {
          if (call) {
            data.session.terminate();
            return;
          }

          setCall(data.session);

          if (data.session.direction === "incoming") {
            setStatusCallio("Có cuộc gọi đến, bấm trả lời để nhận");
          }

          data.session.on("icecandidate", (e) => {
            setTimeout(() => {
              e.ready();
            }, 10000);
          });

          data.session.on("progress", () => {
            setStatusCallio("Đang đổ chuông");
          });

          data.session.on("accepted", () => {
            setStatusCallio("Đang đàm thoại");
          });

          data.session.on("confirmed", () => {
            setStatusCallio("Đang đàm thoại");
          });

          data.session.on("ended", () => {
            setStatusCallio("Đã kết thúc cuộc gọi");
            setCall(null);
          });

          data.session.on("failed", (e) => {
            setStatusCallio("Cuộc gọi thất bại");
            setCall(null);
          });
        });

        setPhone(newPhone);
      } catch (error) {
        alert("Máy tính không có mic hoặc không được cấp quyền dùng mic!");
      }
    };

    initPhone();
  }, []);

  const handleCall = (number) => {
    if (number !== "") {
      const newCall = phone.call(number, callOptions);
      setCall(newCall);

      if (newCall.connection) {
        newCall.connection.onaddstream = function (e) {
          const remoteAudio = document.createElement("audio");
          remoteAudio.srcObject = e.stream;
          remoteAudio.play();
        };
      }

      setStatusCallio("Đang gọi");
    } else {
      alert("Mời nhập số");
    }
  };
  const data = [{ text: "None", value: "" }];
  listProject.map((item) => {
    data.push({
      text: item.project,
      value: item._id,
    });
  });

  useEffect(() => {
    if (!isLoading1) {
      const data = [];
      if (dataCall.data.length > 0) {
        dataCall?.data.map((item) => {
          data.push({
            text: item.group_name,
            value: item._id,
          });
        });
      }
      //   setDataGroup(data);
      // setLoading(true);
    }
    if (!isLoading2) {
      const data = [];
      if (dataCall1.data.length > 0) {
        dataCall1?.data.map((item) => {
          data.push({
            text: item.building_name,
            value: item._id,
          });
        });
      }
      //   setDataBuilding(data);
    }
  }, [dataCall, isLoading1, isLoading2]);

  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const mutation = useMutationHooks(async (data) => {
    const { id, ...rests } = data;
    await TeleSaleService.updateTeleSales(id, rests, access_token);
  });
  const mutationDeleteCallback = useMutationHooks(async (id) => {
    await CallbackService.deleteCallbackById(id, access_token);
  });
  const handleUpdate = async (e) => {
    await mutation.mutate({
      id: id,
      price_sale: priceSale,
      price_rented: priceRent,
      note,
      acreage,
      landlord,
      phone_number_1: phoneNumberOne,
      phone_number_2: phoneNumberTwo,
      interior,
      bedroom_number: bedroomNumber,
      direction: direction ? strToCapitalize(direction) : direction,
      expiration_date: date,
      status,
      userId: userId,
    });
    await mutationDeleteCallback.mutate(callbackId);
    history("/telesale/callback");
  };
  // Lấy dữ liệu gọi lại
  const {
    error: errorUpdate,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess,
  } = mutation;
  const {
    isLoading: isLoadingUpdateLand,
    data: updateLandData,
    isError: isErrorUpdateLand,
    error: errorUpdateLand,
  } = useQuery(["products", access_token], () => CallbackService.getCallbackById(callbackId, access_token));

  // Lấy nhiều căn gọi lại
  // useEffect(() => {
  //   if (isErrorUpdateLand && errorUpdateLand) {
  //     if (!toast.isActive(toastId.current)) {
  //       toastId.current = toast.error(
  //         errorUpdateLand.response.data.message,
  //         Toastobjects
  //       );
  //     }
  //   } else if (updateLandData) {
  //     setID(updateLandData?.data[0]?._id);
  //     setLandlord(updateLandData?.data[0]?.landlord);
  //     setPhoneNumberOne(updateLandData?.data[0]?.phone_number_1);
  //     setPhoneNumberTwo(updateLandData?.data[0]?.phone_number_2);
  //     setAcreage(updateLandData?.data[0]?.acreage);
  //     setInterior(updateLandData?.data[0]?.interior);
  //     setBedRoomNumber(updateLandData?.data[0]?.bedroom_number?.toUpperCase());
  //     setDirection(updateLandData?.data[0]?.direction);
  //     setPriceSale(updateLandData?.data[0]?.price_sale);
  //     setPriceRent(updateLandData?.data[0]?.price_rented);
  //     setNote(updateLandData?.data[0]?.note);
  //     setStatus(updateLandData?.data[0]?.status);
  //     setDate(updateLandData?.data[0]?.expiration_date);
  //   }
  // }, [isErrorUpdateLand, errorUpdateLand, updateLandData]);

  // Lấy 1 căn gọi lại
  useEffect(() => {
    if (isErrorUpdateLand && errorUpdateLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          errorUpdateLand.response.data.message,
          Toastobjects
        );
      }
    } else if (updateLandData) {
      console.log("updateLandData 318", updateLandData)
      setID(updateLandData?.realEstate?._id);
      setLandlord(updateLandData?.realEstate?.landlord);
      setPhoneNumberOne(updateLandData?.realEstate?.phone_number_1);
      setPhoneNumberTwo(updateLandData?.realEstate?.phone_number_2);
      setAcreage(updateLandData?.realEstate?.acreage);
      setInterior(updateLandData?.realEstate?.interior);
      setBedRoomNumber(updateLandData?.realEstate?.bedroom_number?.toUpperCase());
      setDirection(updateLandData?.realEstate?.direction);
      setPriceSale(updateLandData?.realEstate?.price_sale);
      setPriceRent(updateLandData?.realEstate?.price_rented);
      setNote(updateLandData?.realEstate?.note);
      setStatus(updateLandData?.realEstate?.status);
      setDate(updateLandData?.realEstate?.expiration_date);
    }
  }, [isErrorUpdateLand, errorUpdateLand, updateLandData]);

  useEffect(() => {
    if (!errorUpdateLand && isSuccess) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Thành công!", Toastobjects);
      }
      window.location.reload();
    } else if (errorUpdateLand) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          errorUpdateLand.response.data.message,
          Toastobjects
        );
      }
    }
  }, [errorUpdateLand, isSuccess]);

  const createCallbackMutation = useMutation(data => CallbackService.createCallback(data, access_token));
  const handleCallback = async (e) => {
      const data = {
          realEstate: id,
          userId: userId
      };
    await createCallbackMutation.mutateAsync(data);
    handleUpdate();
  }
  return (
    <>
      <Toast />
      {/* {isLoading ? <div>Loading</div> :} */}
      <h1 className="my-3 text-start text-2xl font-semibold">Gọi lại chủ nhà</h1>
      <div className="w-full max-w-6xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow xs:p-6 md:p-8">
        <h3 className="mb-3 text-center text-lg text-[#216cb4] font-medium">
          {updateLandData?.realEstate?.apartment} -{" "}
          {updateLandData?.realEstate?.project?.project} -{" "}
          {updateLandData?.realEstate?.building?.building_name} -{" "}
          {updateLandData?.realEstate?.bedroom_number}
        </h3>
        <div className="space-y-3">
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                htmlFor="landlord"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Chủ nhà <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="landlord"
                value={landlord}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Họ và tên"
                onChange={(e) => setLandlord(e.target.value)}
              />
            </div>
            <div className="mb-5 relative">
              <label
                htmlFor="phone_number_1"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                SĐT 1 <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="phone_number_1"
                name="phone_number_1"
                value={phoneNumberOne}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Số điện thoại"
                onChange={(e) => setPhoneNumberOne(e.target.value)}
              />
              <input
                type="checkbox"
                name="phone_choose"
                className="absolute top-[52%] right-1 w-6 h-6 rounded"
                onClick={() => handleChoosePhone("phone_number_1")}
                checked={phoneChoose == "phone_number_1"}
              />
            </div>
            <div className="relative mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                SĐT 2
              </label>
              <input
                type="text"
                id="phone_number_2"
                name="phone_number_2"
                value={phoneNumberTwo}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Số điện thoại"
                onChange={(e) => setPhoneNumberTwo(e.target.value)}
              />
              <input
                type="checkbox"
                name="phone_choose"
                className="absolute top-[52%] right-1 w-6 h-6 rounded"
                onClick={() => handleChoosePhone("phone_number_2")}
                checked={phoneChoose == "phone_number_2"}
              />
            </div>
          </div>
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                htmlFor="acreage"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Diện tích (m2) <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="acreage"
                name="acreage"
                value={acreage}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Diện tích"
                onChange={(e) => setAcreage(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="interior"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Nội thất <span className="text-red-600">(*)</span>
              </label>
              <select
                id="interior"
                name="interior"
                value={interior}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setInterior(e.target.value)}
              >
                <option selected>Chọn nội thất</option>
                <option selected={interior == 1} value="1">
                  Nội thất cơ bản
                </option>
                <option selected={interior == 2} value="2">
                  Full nội thất
                </option>
                <option selected={interior == 3} value="3">
                  Nguyên bản chủ đầu tư
                </option>
              </select>
            </div>
            <div className="mb-5">
              <label
                htmlFor="room_number"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Số phòng ngủ <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="room_number"
                name="room_number"
                value={bedroomNumber}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Số phòng ngủ"
                onChange={(e) => setBedRoomNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                htmlFor="direction"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Hướng ban công <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="direction"
                name="direction"
                value={direction}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Hướng ban công"
                onChange={(e) => setDirection(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="price_sale"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Giá bán <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="price_sale"
                name="price_sale"
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Giá bán"
                value={priceSale}
                onChange={(e) => setPriceSale(e.target.value)}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="price_rent"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Giá cho thuê <span className="text-red-600">(*)</span>
              </label>
              <input
                type="text"
                id="price_rent"
                name="price_rent"
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Giá cho thuê"
                value={priceRent}
                onChange={(e) => setPriceRent(e.target.value)}
                onBlur={() => formatMoney(priceRent)}
              />
            </div>
          </div>
          {status == "on_rent" && (
            <>
              <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                <div className="mb-5">
                  <label
                    htmlFor="price_rent"
                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                  >
                    Hạn cho thuê
                  </label>
                  <input
                    type="date"
                    id="first_name"
                    className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Hạn cho thuê"
                    value={DateNew(date).split("/").reverse().join("-")}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    onChange={(e) => setDate(e.target.value)}
                  />
                </div>
              </div>
            </>
          )}

          <div className="md:flex md:justify-around">
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                    id="dang_ban_dang_cho_thue"
                    type="radio"
                    name="status"
                    value="on_sale_rent"
                    checked={status === "on_sale_rent"}
                    onClick={() => setStatus("on_sale_rent")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                  htmlFor="dang_ban_dang_cho_thue"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Đang bán & Đang cho thuê
              </label>
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="dang_ban"
                  type="radio"
                  name="status"
                  value="on_sale"
                  checked={status === "on_sale"}
                  onClick={() => setStatus("on_sale")}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="dang_ban"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Đang bán
              </label>
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="dang_cho_thue"
                  type="radio"
                  name="status"
                  value="on_rent"
                  checked={status === "on_rent"}
                  onClick={() => setStatus("on_rent")}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="dang_cho_thue"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Đang cho thuê
              </label>
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="da_ban"
                  type="radio"
                  name="status"
                  value="sold"
                  checked={status === "sold"}
                  onClick={() => setStatus("sold")}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="da_ban"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Đã bán
              </label>
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="da_cho_thue"
                  type="radio"
                  name="status"
                  value="rented"
                  checked={status === "rented"}
                  onClick={() => setStatus("rented")}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="da_cho_thue"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Đã cho thuê
              </label>
            </div>
            <div className="flex items-start mb-5">
              <div className="flex items-center h-5">
                <input
                  id="khong_nhu_cau"
                  type="radio"
                  name="status"
                  value="no_need"
                  checked={status === "no_need"}
                  onClick={() => setStatus("no_need")}
                  className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                />
              </div>
              <label
                htmlFor="khong_nhu_cau"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Không nhu cầu
              </label>
            </div>
          </div>

          <div className="w-full">
            <EditorComponent note={note} setNote={setNote} />
            {/* <textarea
                            className="w-full max-w-5xl"
                            name="note"
                            id="note"
                            rows="5"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        ></textarea> */}
          </div>

          <div className="md:flex md:justify-center md:gap-3">
            <button
              className="w-[160px] text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              onClick={handleUpdate}
            >
              Cập nhật thông tin
            </button>
            <button
              className="w-[160px] border-2 border-blue-700 hover:bg-indigo-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleCallLandlord}
            >
              Gọi chủ nhà
            </button>
            <button
              className="w-[160px] border-2 border-red-700 hover:bg-red-800 hover:text-white focus:ring-4 focus:outline-none focus:ring-indigo-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              onClick={handleEndCall}
            >
              Kết thúc cuộc gọi
            </button>
          </div>
          <p id="status">{statusCallio}</p>
        </div>
      </div>
    </>
  );
}

export default UpdateLand;
