import React from "react"

function AdminStatiscal() {
  return(
      <>
        <div>Đang phát triển</div>
      </>
  )
}

export default AdminStatiscal