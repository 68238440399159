import axios from "axios";
import { API } from "../utils/apiURL";

import jwt_decode from "jwt-decode";

export const createdProject = async (access_token, data) => {
  const res = await axios.post(`${API}/projects`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const getGroupProject = async (access_token, project) => {
  const res = await axios.get(`${API}/projects/groups?project_id=${project}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const getAllProject = async (access_token) => {
  const res = await axios.get(`${API}/projects`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getDetail = async (id, access_token) => {
  const res = await axios.get(`${API}/projects/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const updateProject = async (id, data, access_token) => {
  const res = await axios.patch(`${API}/projects/${id}`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const deleteProjectById = async (id, access_token) => {
  const res = await axios.delete(`${API}/projects/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res;
};

// export const updateAddress = async (data) => {
//   const headers = {
//     Authorization: `Bearer ${data.access_token}`,
//   };
//   const userId = data.id;
//   delete data.id;
//   const res = await axios.post(
//     `${API}/api/v1/user/updateAdress/user/${userId}`,
//     data,
//     {
//       headers,
//     }
//   );

//   return res.data;
// };
