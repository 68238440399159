import React from 'react';
import {useSelector} from "react-redux";
import CreateCallAssignment from "../../components/Telesale/CreateCallAssignment";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from '../../privateRouterv2';

function CreateCallAssignmentPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <CreateCallAssignment/>
            </div>
        </>
    )
}

export default withAuthorization(CreateCallAssignmentPage, ["super_admin","admin"]);