import React from "react";
import Sale from "../../components/Sale/Sale";
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from "../../privateRouterv2";

function SalePage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <Sale/>
            </div>
        </>
    );
}

export default withAuthorization(SalePage, [
    "super_admin",
    "admin",
    "manager",
    "sale",
    "intern"
]);
