import React from "react";
import {useState} from "react";
import {toast} from "react-toastify";
import Toast from "../../components/Toast/Toast";
import * as SaleService from "../../services/SaleService";
import {useMutationHooks} from "../../hooks/useMutationHook";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {TESelect} from "tw-elements-react";
import {useQuery} from "react-query";
import * as ProjectService from "../../services/ProjectService";

function Booking() {
    const [landlord, setLandLord] = useState("");
    const [phone_number, setPhoneNumber] = useState("");
    const [project, setProject] = useState("");
    const [note, setNote] = useState("");

    const userLogin = useSelector((state) => state.user)
    const {id, access_token} = userLogin
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const mutationAddBooking = useMutationHooks((data) => {
        const res = SaleService.createBooking(data, access_token);
        return res;
    });
    const submitHandler = async (event) => {
        event.preventDefault();
        if (
            landlord === "" ||
            phone_number === "" ||
            project === "" ||
            note === ""
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Không được để trống!", Toastobjects);
            }
        } else {
            //   const access_token = JSON.parse(localStorage.getItem("access_token"))
            mutationAddBooking.mutate({
                fullname: landlord,
                phone_number,
                project,
                note,
                created_by: id
            });
        }
    };
    const {error, isLoading, isSuccess, isError} = mutationAddBooking;
    useEffect(() => {
        if (!error && isSuccess) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Tạo Đơn thành công", Toastobjects);
            }
            window.location.reload()
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        }
    }, [error, isSuccess]);

    const [dataProjects, setDataProjects] = React.useState([])
    const {data: projects} = useQuery(["Project", access_token], () => ProjectService.getGroupProject(access_token))
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects?.data?.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({ value: "", text: "None" });
            setDataProjects(newDataProjects);
        }
    }, [projects])
    return (
        <>
            <Toast/>
            <form className="space-y-3" action="#">
                <h1 className="text-2xl font-semibold">Tạo đơn mới</h1>
                <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                    <div className="mb-5">
                        <label
                            htmlFor="landlord"
                            className="block mb-2 text-sm font-medium text-start text-gray-900"
                        >
                            Tên KH
                        </label>
                        <input
                            type="text"
                            id="landlord"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Họ và tên"
                            onChange={(e) => setLandLord(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <label
                            htmlFor="phone_number"
                            className="block mb-2 text-sm font-medium text-start text-gray-900"
                        >
                            SĐT
                        </label>
                        <input
                            type="text"
                            id="phone_number"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Số điện thoại"
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </div>
                    <div className="mb-5">
                        <label
                            htmlFor="project"
                            className="block mb-2 text-sm font-medium text-start text-gray-900"
                        >
                            Dự án
                        </label>
                        <div>
                            <TESelect data={dataProjects} search onValueChange={(e) => setProject(e.value)} placeholder={"Dự án"}/>
                        </div>
                    </div>
                </div>
                <div className="w-full max-w-4xl mx-auto">
                    <label
                        htmlFor="note"
                        className="block mb-2 text-sm font-medium text-start text-gray-900"
                    >
                        Ghi chú
                    </label>
                    <textarea
                        className="w-full"
                        name="note"
                        id="note"
                        rows="5"
                        placeholder="Ghi chú"
                        onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                </div>

                <button
                    onClick={submitHandler}
                    className="text-white bg-indigo-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Tạo đơn
                </button>
            </form>
        </>
    );
}

export default Booking;
