import "./App.css";
import "react-toastify/dist/ReactToastify.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import SalePage from "./pages/Sale/SalePage";
import UserPage from "./pages/User/UserPage";
import AddUserPage from "./pages/User/AddUserPage";
import EditUserPage from "./pages/User/EditUserPage";
import React from "react";
import AddLandPage from "./pages/Land/AddLandPage";
import Login from "./pages/Login/Login";
import CallAssignmentPage from "./pages/Telesale/CallAssignmentPage";
import UpdateLand from "./components/Telesale/UpdateLand";
import UpdateLandPage from "./pages/Telesale/UpdateLandPage";
import CreateCallAssignmentPage from "./pages/Telesale/CreateCallAssignmentPage";
import BookingPage from "./pages/Sale/BookingPage";
import GalleryPage from "./pages/UploadImage/GalleryPage";
import UploadImagePage from "./pages/UploadImage/UploadImagePage";
import ProjectPage from "./pages/Project/ProjectPage";
import EditProjectPage from "./pages/Project/EditProjectPage";
import AddProjectPage from "./pages/Project/AddProjectPage";
import LandPage from "./pages/Land/LandPage";
import EditLandPage from "./pages/Land/EditLandPage";
import ChangePasswordPage from "./pages/User/ChangePasswordPage";
import UpdateProfilePage from "./pages/User/UpdateProfilePage";
import AdminStatiscalPage from "./pages/AdminStatiscal/AdminStatiscalPage";
import PrivateRoutes from "./privateRouter";
import NotFoundPage from "./pages/NotFound/NotFoundPage";
import GroupPage from "./pages/Group/GroupPage";
import AddGroupPage from "./pages/Group/AddGroupPage";
import EditGroupPage from "./pages/Group/EditGroupPage";
import BuildingPage from "./pages/Building/BuildingPage";
import AddBuildingPage from "./pages/Building/AddBuildingPage";
import EditBuildingPage from "./pages/Building/EditBuildingPage";
import CallBackPage from "./pages/Telesale/Callback/CallBackPage";
import UpdateLandCallbackPage from "./pages/Telesale/Callback/UpdateLandCallbackPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/*" element={<NotFoundPage />} />
          <Route path="/" element={<AdminStatiscalPage />} />
          {/* <Route element={<PrivateRoutes />}> */}
          <Route path="/land" element={<LandPage />} />
          <Route path="/land/add" element={<AddLandPage />} />
          <Route path="/land/edit/:id" element={<EditLandPage />} />
          <Route path="/sale" element={<SalePage />} />
          {/* <Route path="/sale/:page" element={<SalePage/>}/> */}
          <Route path="/sale/booking" element={<BookingPage />} />
          <Route
            path="/telesale/update-land"
            element={<UpdateLandPage />}
          />{" "}
          {/*Gọi chủ nhà*/}
          <Route
            path="/telesale/call-assignment"
            element={<CallAssignmentPage />}
          />{" "}
          {/*Phân công gọi*/}
          <Route
            path="/telesale/call-assignment/create"
            element={<CreateCallAssignmentPage />}
          />{" "}
          <Route
              path="/telesale/callback"
              element={<CallBackPage />}
          />{" "}
          <Route
              path="/telesale/callback/:id"
              element={<UpdateLandCallbackPage />}
          />{" "}
          {/*Tạo phân công gọi*/}
          <Route path="/user" element={<UserPage />} />
          <Route path="/user/add" element={<AddUserPage />} />
          <Route path="/user/edit/:id" element={<EditUserPage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/upload-image" element={<UploadImagePage />} />
          <Route path="/project" element={<ProjectPage />} />
          <Route path="/project/add" element={<AddProjectPage />} />
          <Route path="/project/edit/:id" element={<EditProjectPage />} />
          <Route path="/group" element={<GroupPage />} />
          <Route path="/group/add" element={<AddGroupPage />} />
          <Route path="/group/edit/:id" element={<EditGroupPage />} />
          <Route path="/building" element={<BuildingPage />} />
          <Route path="/building/add" element={<AddBuildingPage />} />
          <Route path="/building/edit/:id" element={<EditBuildingPage />} />
          <Route path="/user/edit/:id" element={<EditUserPage />} />
          <Route path="user/change-password" element={<ChangePasswordPage />} />
          <Route path="user/update-profile" element={<UpdateProfilePage />} />
          {/* </Route> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
