import React, { useEffect } from "react";
import { getAll } from "../../functions/saleFunction";
import { useDispatch } from "react-redux";
import { updateStatus } from "../../features/statusSlice/statusSlice";

function Statisfical({ data, statusOrder, totalNoApproached, totalAppointment, totalNet, totalNoNet, totalStake, totalStaked, totalContract, totalLost }) {
  statusOrder.no_approached.count = totalNoApproached;
  statusOrder.appointment.count = totalAppointment;
  statusOrder.net.count = totalNet;
  statusOrder.no_net.count = totalNoNet;
  // statusOrder.stake.count = totalStake;
  statusOrder.staked.count = totalStaked;
  statusOrder.contract.count = totalContract;
  statusOrder.lost.count = totalLost;
  
  const dispatch = useDispatch();
  const handleClick = async (field) => {
    dispatch(updateStatus({ type: field }));
  };
  return (
    <>
      <div className="md:flex gap-3">
        {Object.keys(statusOrder).map((field) => (
          <div
            className={`w-full max-w-xs bg-[${statusOrder[field].bg}] border border-gray-200 rounded-lg shadow`}
          >
            <button onClick={() => handleClick(field)}>
              <div className="flex flex-col items-center py-5 hover:opacity-80">
                <h5 className="mb-1 text-3xl font-medium text-white ">
                  {statusOrder[field]?.count}
                </h5>
                <span className="text-sm text-white font-medium">
                  {statusOrder[field]?.title}
                </span>
              </div>
            </button>
          </div>
        ))}
      </div>
    </>
  );
}

export default Statisfical;
