export const customeDate = (date) => {
  const date1 = new Date(date);
  const dateNow = new Date();
  const differenceInMilliseconds = dateNow - date1;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / (1000 * 60 * 60 * 24)
  );
  return differenceInDays;
};

export const DateNew = (date, type = "") => {
  let formattedDate;
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  formattedDate = `${day}/${month}/${year}`;
  // formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formattedDate;
};

export const DateTime = (date, type = "") => {
  let formattedDate;
  const dateObj = new Date(date);
  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  // formattedDate = `${day}/${month}/${year}`;
  formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;
  return formattedDate;
};
