import React from "react"
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import AdminStatiscal from "../../components/AdminStatiscal/AdminStatiscal";
import withAuthorization from "../../privateRouterv2";

function AdminStatiscalPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 sm:ml-64" : "p-4"}>
                <AdminStatiscal/>
            </div>
        </>
    )
}

export default withAuthorization(AdminStatiscalPage,["admin","super_admin","manager","sale","telesale", "intern"])