export const customStatusLand = (status) => {
    let text = ''
    switch (status) {
        case "no_need":
            text = 'Không nhu cầu'
            break;
        case "no_phone":
            text = 'Không nghe điện thoại'
            break;
        case "sold":
            text = 'Đã bán'
            break;
        case "on_sale":
            text = 'Đang bán'
            break;
        case "rented":
            text = 'Đã thuê'
            break;
        case "on_rent":
            text = 'Đang cho thuê'
            break;
        case "on_sale_rent":
            text = 'Đang cho thuê & bán'
            break;
        default:
            text = ''
            break;
    }
    return text;
}