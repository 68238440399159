import React, { useEffect, useState } from "react";
import Gallery from "../../components/UploadImage/Gallery";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import Pagination from "../../components/Pagination/Pagination";
import * as NewSerivce from "../../services/NewSerivce";

function GalleryPage() {
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
  const [searchParams] = useSearchParams();
  const codeParams = searchParams.get("code") || "";
  const apartmentSoldParams = searchParams.get("apartmentSold") || "";
  const pageParams = searchParams.get("page") || "";
  const [code, setCode] = React.useState(codeParams);
  const [apartmentSold, setApartmentSold] = React.useState(apartmentSoldParams);
  const [active, setActive] = React.useState(1);
  const [datas, setData] = React.useState([]);
  const [dataAll, setDataAll] = React.useState([]);
  const [loading, setLoading] = useState(true);
  //   const {
  //     isLoading,
  //     data: dataModel,
  //     isError,
  //     error,
  //   } = useQuery(["Warehouse", code], () => getAll(code));
  //   const datas = dataModel?.data;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await NewSerivce.getAll(code, apartmentSold, pageParams);
      if (res) {
        setData(res.data);
        setDataAll(res);
        setLoading(false);
      }
    };
    fetchData();
  }, [code, apartmentSold , pageParams]);
  return (
    <>
      <Sidebar />
      <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
        <h1 className="my-2 text-2xl uppercase font-semibold text-start">
          Ảnh căn hộ
        </h1>
        <div className="my-3 xs:text-start md:text-end">
          <Link
            to="/upload-image"
            className="text-white bg-[#008d4c] hover:bg-[#5cb85c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Thêm mới
          </Link>
        </div>
        <div className="flex gap-3">
          <div className="text-start my-2">
            <input
                type="text"
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setCode(e.target.value)}
                placeholder="Mã tin"
            />
          </div>
          <div className="text-start my-2">
            <input
                type="text"
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                onChange={(e) => setApartmentSold(e.target.value)}
                placeholder="Căn hộ đã bán"
            />
          </div>
        </div>

        {loading ? (
          <div>loading.....</div>
        ) : (
          <>
            {datas && datas.length > 0 && (
              <>
                <Pagination
                  data={dataAll}
                  active={active}
                  setActive={setActive}
                />
                {datas.map((item, index) => (
                  <Gallery key={index} data={item} />
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default GalleryPage;
