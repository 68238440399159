import React from "react";
import EditUser from "../../components/User/EditUser";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from "../../privateRouterv2";

function EditUserPage() {
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);

  return (
    <>
      <Sidebar />
      <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
        <EditUser />
      </div>
    </>
  );
}

export default withAuthorization(EditUserPage, ["super_admin"]);
