import axios from "axios";
import { API } from "../utils/apiURL";

export const getALl = async (
  data,
  status,
  phone_number,
  fullname,
  access_token
) => {
  let apiUrl = `${API}/sales/me?page=${data}&phone_number=${phone_number}&fullname=${fullname}`;
  if (status) apiUrl += `&status=${status}`;

  const res = await axios.get(apiUrl, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });

  return res.data;
};
export const createBooking = async (data, access_token) => {
  const res = await axios.post(`${API}/sales/orders`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getALLRent = async (
  page,
  code,
  project,
  group,
  building,
  interior,
  kind_of,
  direction,
  bedroom_number,
  room,
  creadAt,
  updateAt,
  access_token,
  price_sale
) => {
  const res = await axios.get(`${API}/sales/warehouse-rented?page=${page}&code=${code}&project=${project}&group=${group}&building=${building}&interior=${interior}&kind_of=${kind_of}&direction=${direction}&bedroom_number=${bedroom_number}&room=${room}&creadAt=${creadAt}&updateAt=${updateAt}&price_rented=${price_sale}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getALLSale = async (data, access_token) => {
  const res = await axios.get(`${API}/sales/warehouse-sale?page=${data}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const updateStatusOrder = async (id, data, access_token) => {
  const res = await axios.patch(`${API}/sales/orders/${id}`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const findALl = async (
  page,
  code,
  project,
  group,
  building,
  interior,
  kind_of,
  direction,
  bedroom_number,
  room,
  status,
  creadAt,
  updateAt,
    access_token,
    price_sale
) => {
  const res = await axios.get(
    `${API}/sales/warehouse-sale?page=${page}&code=${code}&project=${project}&group=${group}&building=${building}&interior=${interior}&kind_of=${kind_of}&direction=${direction}&bedroom_number=${bedroom_number}&room=${room}&status=${status}&creadAt=${creadAt}&updateAt=${updateAt}&price_sale=${price_sale}`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
  );
  return res.data;
};
// export const getUserById = async (id) => {
//   const res = await axios.get(`${API}/users/${id}`);
//   return res.data;
// };

// export const updateUser = async (data) => {
//   const userId = data.id;
//   delete data.id;
//   const res = await axios.patch(`${API}/users/${userId}`, data);
//   return res.data;
// };

// export const deleteUserById = async (id) => {
//   const res = await axios.delete(`${API}/users/${id}`);
//   return res.data;
// };
