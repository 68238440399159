import React, {useEffect, useState} from "react";
import {useMutationHooks} from "../../hooks/useMutationHook";
import * as ProjectService from "../../services/ProjectService";
import {getDetail} from "../../functions/projectFunction";
import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import {toast} from "react-toastify";
import Toast from "../../components/Toast/Toast";
import {useSelector} from "react-redux";

function EditProject() {
    const [project, setProject] = useState("");
    const [group, setGroup] = useState("");
    const [buildingCode, setBuildingCode] = useState("");
    const [buildingNumber, setBuildingNumber] = useState("");
    const {id} = useParams();
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const mutation = useMutationHooks(async (data) => {
        const {id, access_token, ...rests} = data;
        await ProjectService.updateProject(id, rests, access_token);
    });
    const handleUpdate = async (e) => {
        e.preventDefault();
        await mutation.mutate({
            id: id,
            project,
            access_token
        });
    };
    const {isLoading, data, isError, error} = useQuery(["project", id, access_token], () =>
        getDetail(id, access_token)
    );
    const {
        error: errorUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess,
    } = mutation;

    useEffect(() => {
        if (isError && error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        } else {
            setProject(data?.data.project);
        }
    }, [isError, error, data]);
    useEffect(() => {
        if (!error && isSuccess) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Sửa dự án thành công!", Toastobjects);
            }
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Sửa dự án thất bại!", Toastobjects);
            }
        }
    }, [error, isSuccess]);
    return (
        <>
            <Toast/>
            <div
                className="w-full max-w-5xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" action="#">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                        Sửa thông tin dự án
                    </h5>
                    <div className="md:flex md:justify-center md:gap-2">
                        <div className="mb-5">
                            <label
                                htmlFor="project"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Dự án <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="project"
                                value={project}
                                onChange={(e) => setProject(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Dự án"
                                required
                            />
                        </div>
                    </div>
                    {/*<div className="md:flex md:justify-center md:gap-2">*/}
                    {/*   */}
                    {/*    <div className="mb-5">*/}
                    {/*        <label htmlFor="number_floor_from" className="block mb-2 text-sm font-medium text-start text-gray-900">Từ tầng  <span className="text-red-600">(*)</span></label>*/}
                    {/*        <input type="number" id="number_floor_from" value="5"*/}
                    {/*               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
                    {/*               placeholder="Số tầng" required/>*/}
                    {/*    </div>*/}
                    {/*    <div className="mb-5">*/}
                    {/*        <label htmlFor="number_floor_to" className="block mb-2 text-sm font-medium text-start text-gray-900">Đến tầng  <span className="text-red-600">(*)</span></label>*/}
                    {/*        <input type="number" id="number_floor_to" value="10"*/}
                    {/*               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
                    {/*               placeholder="Số tầng" required/>*/}
                    {/*    </div>*/}
                    {/*    <div className="mb-5">*/}
                    {/*        <label htmlFor="number_department" className="block mb-2 text-sm font-medium text-start text-gray-900">Số căn mỗi tầng <span className="text-red-600">(*)</span></label>*/}
                    {/*        <input type="number" id="number_department" value="10"*/}
                    {/*               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"*/}
                    {/*               placeholder="Số căn" required/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={handleUpdate}
                    >
                        Sửa thông tin
                    </button>
                </form>
            </div>
        </>
    );
}

export default EditProject;
