import React, {useEffect} from "react";
import {useQuery} from "react-query";
import {Link} from "react-router-dom";
import {deleteProjectById, getAll} from "../../functions/projectFunction";
import {toast} from "react-toastify";
import Toast from "../../components/Toast/Toast";
import {useDispatch, useSelector} from "react-redux";
import {updateProject} from "../../features/projectSlice/projectSlice";

function Project() {
    const toastId = React.useRef(null);
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;
    const dispatch = useDispatch();
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const {isLoading, data, isError, error} = useQuery(
        ["project", access_token],
        () => getAll(access_token)
    );
    const handeDeleteUser = async (id) => {
        if (window.confirm("Bạn muốn xóa project?")) {
            const data = await deleteProjectById(id, access_token);
            if (data?.data.success) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(data.message, Toastobjects);
                }
                window.location.reload();
            } else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(data.message, Toastobjects);
                }
            }
        }
    };
    useEffect(() => {
        if (data) {
            dispatch(updateProject({listProject: data.data}));
        }
    }, [data]);
    return (
        <>
            <Toast/>
            <h1 className="mt-3 text-start text-2xl font-semibold">
                Danh sách dự án
            </h1>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex justify-end">
                    <Link
                        to="/project/add"
                        className="px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
                    >
                        Thêm mới Dự án
                    </Link>
                </div>

                <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-blue-500">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            STT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Dự án
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Hành động</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <div>LOADING ...</div>
                    ) : (
                        data?.data.map((item, index) => (
                            <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                                <td className="px-6 py-4">{index + 1}</td>
                                <td className="px-6 py-4">{item?.project}</td>
                                <td className="space-x-2 px-6 py-4 text-right">
                                    <Link
                                        to={`/project/edit/${item._id}`}
                                        className="font-medium text-blue-600 hover:underline"
                                    >
                                        Sửa
                                    </Link>
                                    <button
                                        className="font-medium text-red-600 hover:underline"
                                        onClick={() => handeDeleteUser(item._id)}
                                    >
                                        Xóa
                                    </button>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Project;
