import React, {useEffect} from "react";
import Toast from "../Toast/Toast";
import {Link, useSearchParams} from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import {useQuery} from "react-query";
import {getAll} from "../../functions/RealEstateFunction";
import {customStatusLand} from "../../functions/StatusLand";
import {DateNew, DateTime} from "../../functions/CustomeDate";
import {toast} from "react-toastify";
import {deleteLandById} from "../../functions/landFunctions";
import {useSelector} from "react-redux";
import {TESelect} from "tw-elements-react";
import * as ProjectService from "../../services/ProjectService";
import {useMutationHooks} from "../../hooks/useMutationHook";
import * as GroupService from "../../services/GroupService";
import * as BuildingService from "../../services/BuildingService";

function Land() {
    const userLogin = useSelector((state) => state.user);
    const {access_token, roles} = userLogin;
    const [code, setCode] = React.useState("");
    const [project, setProject] = React.useState("");
    const [group, setGroup] = React.useState("");
    const [building, setBuilding] = React.useState("");
    const [interior, setInterior] = React.useState("");
    const [kindOf, setKindOf] = React.useState("");
    const [direction, setDirection] = React.useState("");
    const [bedroomNumber, setBedroomNumber] = React.useState("");
    const [room, setRoom] = React.useState("");
    const [apartment, setApartment] = React.useState("");
    const [dataProjects, setDataProjects] = React.useState([]);
    const [dataGroups, setDataGroups] = React.useState([]);
    const [dataBuilding, setDataBuilding] = React.useState([]);
    const [active, setActive] = React.useState(1);
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const [searchParams, setSearchParams] = useSearchParams();
    const pageParams = searchParams.get("page") || 1;

    const {isLoading, data, isError, error} = useQuery(
        [
            "RealEstate",
            access_token,
            pageParams,
            code,
            project,
            group,
            building,
            kindOf,
            apartment
        ],
        () =>
            getAll(access_token, pageParams, code, project, group, building, kindOf, apartment)
    );

    const handeDeleteLand = async (id) => {
        if (window.confirm("Bạn muốn xóa Land?") == true) {
            const data = await deleteLandById(id, access_token);
            if (data.success) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(data.message, Toastobjects);
                }
                // window.location.reload();
            } else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(data.message, Toastobjects);
                }
            }
        }
    };
    const {data: projects} = useQuery(["Project", access_token], () =>
        ProjectService.getGroupProject(access_token)
    );
    const mutationGetGroupByProjectId = useMutationHooks((data) => {
        const res = GroupService.getGroupByProjectId(data, access_token);
        return res;
    });
    const mutationGetBuildingByGroupId = useMutationHooks((id) => {
        const res = BuildingService.getAllBuilding(project, id, access_token);
        return res;
    });
    const {
        error: errorGetGroupByProjectId,
        isSuccess: isSuccessGetGroupByProjectId,
        isError: isErrorGetGroupByProjectId,
        data: dataGetGroupByProjectId,
    } = mutationGetGroupByProjectId;
    const {
        error: errorGetBuildingByGroupId,
        isSuccess: isSuccessGetBuildingByGroupId,
        isError: isErrorGetBuildingByGroupId,
        data: dataGetBuildingByGroupId,
    } = mutationGetBuildingByGroupId;
    const handleChangeProject = async (value) => {
        setProject(value);
        await mutationGetGroupByProjectId.mutate(value);
    };
    const handleChangeGroup = async (value) => {
        setGroup(value);
        await mutationGetBuildingByGroupId.mutate(value);
    };
    const handleChangeBuilding = (value) => {
        setBuilding(value);
    };
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects.data.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({value: "", text: "None"});
            setDataProjects(newDataProjects);
        }
    }, [projects]);
    //Cap nhat du lieu Group theo Project Id
    useEffect(() => {
        if (
            dataGetGroupByProjectId?.data &&
            dataGetGroupByProjectId.data.length > 0
        ) {
            const newDataGroups = dataGetGroupByProjectId.data.map((item) => ({
                value: item._id,
                text: item.group_name,
            }));
            newDataGroups.unshift({value: "", text: "None"});
            setDataGroups(newDataGroups);
        }
    }, [dataGetGroupByProjectId]);

    //Cap nhat du lieu Building theo Group Id
    useEffect(() => {
        setDataBuilding([]);
        if (
            dataGetBuildingByGroupId?.data &&
            dataGetBuildingByGroupId.data.length > 0
        ) {
            const newDataBuilding = dataGetBuildingByGroupId.data.map((item) => ({
                value: item._id,
                text: item.building_name,
            }));
            newDataBuilding.unshift({value: "", text: "None"});
            setDataBuilding(newDataBuilding);
        }
    }, [dataGetBuildingByGroupId]);

    return (
        <>
            <>
                <Toast/>
                <h1 className="mt-3 text-start text-2xl font-semibold">
                    Danh sách Bất động sản
                </h1>

                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <div className="flex sm:justify-end my-3">
                        <Link
                            to="/land/add"
                            className="px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
                        >
                            Thêm mới BĐS
                        </Link>
                    </div>

                    <div className="grid sm:grid-cols-4 md:grid-cols-6 gap-1 mb-2 ">
                        <div>
                            <input
                                type="text"
                                id="code"
                                onChange={(e) => setCode(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Mã tin"
                            />
                        </div>
                        <div>
                            <TESelect
                                data={dataProjects}
                                search
                                onClick={(e) => setGroup("")}
                                onValueChange={(e) => handleChangeProject(e.value)}
                                placeholder={"Dự án"}
                            />
                        </div>
                        <div>
                            <TESelect
                                data={dataGroups}
                                search
                                // onClick={(e) => setBuilding("")}
                                onValueChange={(e) => handleChangeGroup(e.value)}
                                placeholder={"Phân khu"}
                            />
                        </div>
                        <div className="">
                            <TESelect
                                data={dataBuilding}
                                search
                                onValueChange={(e) => handleChangeBuilding(e.value)}
                                placeholder={"Tòa"}
                            />
                        </div>
                        <div className="">
                            <select
                                id="kind_of"
                                // value={kindOf}
                                onChange={(e) => setKindOf(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            >
                                <option value="" selected>
                                    Chọn loại bất động sản
                                </option>
                                <option value="1">Căn hộ chung cư</option>
                                <option value="2">Căn hộ penthouse</option>
                                <option value="3">Căn hộ Duplex</option>
                                <option value="4">Căn hộ Studio</option>
                                <option value="5">OfficeTel</option>
                                <option value="6">Biệt thự liền kề</option>
                                <option value="7">ShopHouse</option>
                                <option value="8">Căn hộ đập thông</option>
                            </select>
                        </div>
                        <div>
                            <input
                                type="text"
                                id="apartment"
                                onChange={(e) => setApartment(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Căn hộ"
                            />
                        </div>
                    </div>

                    {isLoading ? (
                        <div>Loading</div>
                    ) : (
                        <Pagination data={data} active={active} setActive={setActive}/>
                    )}

                    <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-50 uppercase bg-blue-500">
                        <tr>
                            <th scope="col" className="px-3 py-3 text-center">
                                STT
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Dự án
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Phân khu
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Tòa
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Căn hộ
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Chủ nhà
                            </th>
                            <th scope="col" className="px-3 py-3">
                                SĐT chủ nhà
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Trạng thái
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Người sửa cuối
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Ngày sửa
                            </th>
                            <th scope="col" className="px-3 py-3">
                                <span className="sr-only">Hành động</span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {isLoading ? (
                            <div>Loading</div>
                        ) : (
                            data?.data.map((item, index) => (
                                <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                                    <td className="px-3 py-4 text-center">{index + 1}</td>
                                    <td className="px-3 py-4">{item?.project.project}</td>
                                    <td className="px-3 py-4">{item.group?.group_name}</td>
                                    <td className="px-3 py-4">
                                        {item.building?.building_name}
                                    </td>
                                    <td className="px-3 py-4">{item.apartment}</td>
                                    <td className="px-3 py-4">{item.landlord}</td>
                                    <td className="px-3 py-4">
                                        {item.phone_number_1}
                                        {item.phone_number_2 ?? ` / ${item.phone_number_2}`}
                                    </td>
                                    <td className="px-3 py-4">
                                        {customStatusLand(item.status)}
                                    </td>
                                    <td className="px-3 py-4">{item?.userId?.fullname}</td>
                                    <td className="px-3 py-4">{DateTime(item.updatedAt)}</td>
                                    <td className="space-x-2 px-3 py-4 text-right">
                                        <Link
                                            to={`/land/edit/${item._id}`}
                                            className={
                                                roles[0] == "manager"
                                                    ? "hidden"
                                                    : "font-medium text-blue-600 hover:underline"
                                            }
                                        >
                                            Sửa
                                        </Link>
                                        <a
                                            href="#"
                                            className={
                                                roles[0] == "super_admin"
                                                    ? "font-medium text-red-600 hover:underline"
                                                    : "hidden"
                                            }
                                            onClick={() => handeDeleteLand(item._id)}
                                        >
                                            Xóa
                                        </a>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>
            </>
        </>
    );
}

export default Land;
