import React, {useEffect} from "react"
import Toast from "../Toast/Toast";
import {TESelect} from "tw-elements-react";
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import * as ProjectService from "../../services/ProjectService";
import * as GroupService from "../../services/GroupService";
import {useMutationHooks} from "../../hooks/useMutationHook";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";

function EditGroup() {
    const {id} = useParams();
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;
    const {data: groupData} = useQuery(["Group", id, access_token], () => GroupService.getGroupById(id, access_token))

    const [project, setProject] = React.useState("");
    const [group, setGroup] = React.useState("");

    const [dataProjects, setDataProjects] = React.useState([])

    const {data: projects} = useQuery(["Project", access_token], () => ProjectService.getGroupProject(access_token))

    const mutationEditGroup = useMutationHooks((data) => {
        const res = GroupService.editGroup(id, data, access_token);
        return res;
    })

    const {error: errorEditGroup, isSuccess: isSuccessEditGroup, isError: isErrorEditGroup, data: dataEditGroup} = mutationEditGroup;
    const submitHandler = async (e) => {
        e.preventDefault();
        const data = {
            project_id: project,
            group_name: group
        };
        await mutationEditGroup.mutate(data);
    }

    const handleChangeProject = (value) => {
        setProject(value);
    }

    //Lay du lieu tu api ve va gan vao dataProjects de render ra select
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects.data.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({ value: "", text: "None" });
            setDataProjects(newDataProjects);
        }
    }, [projects])

    //Cap nhat du lieu trong cac o input
    useEffect(() => {
        setProject(groupData?.data?.project_id?._id)
        setGroup(groupData?.data?.group_name)
    },[groupData])

    //thong bao sau khi nhan sua
    useEffect(() => {
        if (!errorEditGroup && isSuccessEditGroup) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Sửa phân khu thành công", Toastobjects
                );
            }
        } else if (errorEditGroup) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Sửa phân khu không thành công", Toastobjects);
            }
        }
    },[errorEditGroup, isSuccessEditGroup])
    return (
        <>
            <Toast/>
            <div
                className="w-full max-w-5xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" action="#">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                        Sửa Phân khu
                    </h5>
                    <div className="md:flex md:justify-center md:gap-2">
                        <div className="mb-5">
                            <label
                                htmlFor="project"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Dự án <span className="text-red-600">(*)</span>
                            </label>
                                <TESelect
                                    data={dataProjects}
                                    search
                                    value={project}
                                    onValueChange={(e) => handleChangeProject(e.value)}
                                    placeholder={"Dự án"}
                                />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="group"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Phân khu{" "}
                            </label>
                            <input
                                type="text"
                                id="group"
                                value={group}
                                onChange={(e) => setGroup(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Phân khu"
                            />
                        </div>
                    </div>
                    <button
                        onClick={submitHandler}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Sửa Phân khu
                    </button>
                </form>
            </div>
        </>
    )
}

export default EditGroup