import React from "react";
import Project from "../../components/Project/Project";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from "../../privateRouterv2";

function ProjectPage() {
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
  return (
    <>
      <Sidebar />
      <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
        <Project />
      </div>
    </>
  );
}

export default withAuthorization(ProjectPage, ["super_admin", "admin"]);
