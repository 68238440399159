import axios from "axios";
import { API } from "../utils/apiURL";

export const getAll = async (access_token,pageParams,code,project,group,building,kindOf,apartment) => {
  let apiUrl = `${API}/real-estate`;
  //   if (status) apiUrl += `&status=${status}`;
  const res = await axios.get(`${API}/real-estate?page=${pageParams}&code=${code}&project=${project}&group=${group}&building=${building}&kind_of=${kindOf}&apartment=${apartment}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  //   console.log("🚀 ~ file: RealEstateService.js:9 ~ getAll ~ r:", res)
  return res.data;
};
export const getDetail = async (id, access_token) => {
  const res = await axios.get(`${API}/real-estate/apartment/${id}`, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};

export const updateRealEstate = async (id, data, access_token) => {
  const res = await axios.patch(`${API}/real-estate/manager/${id}`, data, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};

export const getDetailsById = async (id, access_token) => {
  const res = await axios.get(`${API}/real-estate/details/${id}`, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};

export const UpdateImg = async (realEstate, data, access_token) => {
  const res = await axios.patch(`${API}/real-estate/${realEstate}`, data, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};
export const DeleteRealEstate = async (apartment, data, access_token) => {
  const res = await axios.delete(`${API}/real-estate/${apartment}`, data, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};
export const getApporre = async (access_token) => {
  //   if (status) apiUrl += `&status=${status}`;
  const res = await axios.get(`${API}/real-estate/apporre`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  //   console.log("🚀 ~ file: RealEstateService.js:9 ~ getAll ~ r:", res)
  return res.data;
};
export const updateApporre = async (id, access_token) => {
  const res = await axios.patch(`${API}/real-estate/appporre/${id}`,{
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res.data;
};

export const importFileExcel = async (data, access_token) => {
  const res = await axios.post(`${API}/real-estate/import`, data, {
    headers: {
        Authorization: `Bearer ${access_token}`,
    }
  });
  return res;
};

// export const createBooking = async (data) => {
//   const res = await axios.post(`${API}/sales/orders`, data);
//   return res.data;
// };
// export const getALLRent = async (data) => {
//   const res = await axios.get(`${API}/sales/warehouse-rented?query=${data}`);
//   return res.data;
// };
// export const getALLSale = async (data) => {
//   const res = await axios.get(`${API}/sales/warehouse-sale?query=${data}`);
//   return res.data;
// };
// export const updateStatusOrder = async (id,data) => {
//   const res = await axios.patch(`${API}/sales/orders/${id}`, data);
//   return res.data;
// };
// export const getUserById = async (id) => {
//   const res = await axios.get(`${API}/users/${id}`);
//   return res.data;
// };

// export const updateUser = async (data) => {
//   const userId = data.id;
//   delete data.id;
//   const res = await axios.patch(`${API}/users/${userId}`, data);
//   return res.data;
// };

// export const deleteUserById = async (id) => {
//   const res = await axios.delete(`${API}/users/${id}`);
//   return res.data;
// };
