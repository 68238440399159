import React, { useState, useEffect, useRef } from "react";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.css";
import htmlToDraft from "html-to-draftjs";

export const EditorComponent = ({ note, setNote }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const previousNoteRef = useRef(true);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setNote(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    if (note) {
      const blocksFromHtml = htmlToDraft(note);
      if (blocksFromHtml) {
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    }
  }, []);

  return (
    <div className="w-full">
      <label
        htmlFor="note"
        className="block mb-2 md:ml-8 text-sm font-medium text-start text-gray-900"
      >
        Ghi chú
      </label>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor w-full max-w-5xl"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  );
};
