import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const withAuthorization = (WrappedComponent, allowedRoles) => {
  const WithAuthorization = (props) => {
    const userLogin = useSelector((state) => state.user);
    const { access_token, roles } = userLogin;
    const navigate = useNavigate();
    const isAuthenticated = access_token;
    const userRoles = roles;

    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/login");
      } else if (!userRoles.some((role) => allowedRoles.includes(role))) {
        navigate("/not-found");
      }
    }, [isAuthenticated, userRoles, allowedRoles, navigate]);

    return <WrappedComponent {...props} />;
  };

  return WithAuthorization;
};

export default withAuthorization;
