import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "tw-elements-react/dist/css/tw-elements-react.min.css";

import App from "./App";
import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { PersistGate } from "redux-persist/integration/react";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>

    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);