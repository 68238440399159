import React from "react"
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import ChangePassword from "../../components/User/ChangePassword";

function ChangePasswordPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <ChangePassword/>
            </div>

        </>
    )
}

export default ChangePasswordPage