import React, { useEffect } from "react";
import { useMutationHooks } from "../../hooks/useMutationHook";
import * as UserService from "../../services/Userservice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast";
function UpdateProfile() {
  const [email, setEmail] = React.useState("");
  const [phone_number, setPhoneNumber] = React.useState("");
  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const userLogin = useSelector((state) => state.user);
  const { id: userId, access_token } = userLogin;
  const mutation = useMutationHooks(async (data) => {
    //   const { id, access_token, ...rests } = data;
    await UserService.updateUser(data, access_token);
  });
  const {
    error,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess,
  } = mutation;
  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!email || !phone_number) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Vui lòng nhập đầy đủ thông tin!",
          Toastobjects
        );
      }
    } else {
      await mutation.mutate({
        id: userId,
        email,
        phone_number,
      });
    }
    // mutation.mutate(decoded?.id, { phone, name, text, sex })
  };
  useEffect(() => {
    if (!error && isSuccess) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success("Thành công!", Toastobjects);
      }
    } else if (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
  }, [error, isSuccess]);
  return (
    <>
    <Toast/>
      <div className="w-full max-w-4xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-6" >
          <h5 className="text-xl font-medium text-gray-900 dark:text-white">
            Thông tin tài khoản
          </h5>
          <div className="md:flex md:justify-center md:gap-2">
            <div className="mb-5">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="phone_number"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Số điện thoại
              </label>
              <input
                type="text"
                id="phone_number"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Số điện thoại"
              />
            </div>
          </div>
          <button
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            onClick={handleUpdate}
          >
            Sửa thông tin
          </button>
        </form>
      </div>
    </>
  );
}

export default UpdateProfile;
