import React from "react";
import Main from "../../components/User/Main";
import { useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from "../../privateRouterv2";

const UserPage = () => {
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
  return (
    <>
      <Sidebar />
      <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
        <Main />
      </div>
    </>
  );
};

export default withAuthorization(UserPage, ["super_admin"]);
