import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  listProject: [],
};

export const projectSlide = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateProject: (state, action) => {
      const { listProject } = action.payload;
      //   console.log("🚀 ~ file: projectSlice.js:13 ~ listProject:", listProject)
      if (listProject && listProject.length > 0) {
        listProject.forEach((item) => {
          if (
            !state.listProject.some(
              (existingItem) => existingItem._id === item._id
            )
          ) {
            state.listProject.push(item);
          }
        });
      }
    },
    resetProject: (state) => {
      state.listProject = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateProject, resetProject } = projectSlide.actions;

export default projectSlide.reducer;
