import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import Toast from "../Toast/Toast";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import * as BuildingService from "../../services/BuildingService";
import {getAllBuilding} from "../../services/BuildingService";
import * as GroupService from "../../services/GroupService";
import {toast} from "react-toastify";
import * as ProjectService from "../../services/ProjectService";
import {useMutationHooks} from "../../hooks/useMutationHook";
import {TESelect} from "tw-elements-react";
import Pagination from "../Pagination/Pagination";

function Building() {
    const [project, setProject] = React.useState("");
    const [group, setGroup] = React.useState("");
    const [active, setActive] = React.useState(1);
    const [dataProjects, setDataProjects] = React.useState([]);
    const [dataGroups, setDataGroups] = React.useState([]);
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;

    const {isLoading, data, isError, error} = useQuery(
        ["Building", project, group, access_token,active],
        () => BuildingService.getAllBuilding(project, group, access_token,active)
    );

    const handeDeleteBuilding = async (id) => {
        if (window.confirm("Bạn muốn xóa tòa?")) {
            const data = await BuildingService.deleteBuildingById(id, access_token);
            if (data?.data.success) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(data.message, Toastobjects);
                }
                window.location.reload();
            } else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(data.message, Toastobjects);
                }
            }
        }
    };

    const {data: projects} = useQuery(["Project", access_token], () =>
        ProjectService.getGroupProject(access_token)
    );
    const mutationGetGroupByProjectId = useMutationHooks((data) => {
        const res = GroupService.getGroupByProjectId(data, access_token);
        return res;
    });

    const {
        error: errorGetGroupByProjectId,
        isSuccess: isSuccessGetGroupByProjectId,
        isError: isErrorGetGroupByProjectId,
        data: dataGetGroupByProjectId,
    } = mutationGetGroupByProjectId;

    const handleChangeProject = async (value) => {
        setProject(value);
        await mutationGetGroupByProjectId.mutate(value);
    };
    const handleChangeGroup = (value) => {
        setGroup(value);
    };
    //Cap nhat du lieu Project
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects.data.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({value: "", text: "None"});
            setDataProjects(newDataProjects);
        }
    }, [projects]);

    //Cap nhat du lieu Group theo Project Id
    useEffect(() => {
        if (
            dataGetGroupByProjectId?.data &&
            dataGetGroupByProjectId.data.length > 0
        ) {
            const newDataGroups = dataGetGroupByProjectId.data.map((item) => ({
                value: item._id,
                text: item.group_name,
            }));
            newDataGroups.unshift({value: "", text: "None"});
            setDataGroups(newDataGroups);
        }
    }, [dataGetGroupByProjectId]);
    return (
        <>
            <Toast/>
            <h1 className="mt-3 text-start text-2xl font-semibold">Danh sách Tòa</h1>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex justify-end">
                    <Link
                        to="/building/add"
                        className="px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
                    >
                        Thêm mới Tòa
                    </Link>
                </div>
                <div className="flex justify-start">
                    <TESelect
                        data={dataProjects}
                        search
                        onClick={() => setGroup("")}
                        onValueChange={(e) => handleChangeProject(e.value)}
                        placeholder={"Dự án"}
                    />
                    <TESelect
                        data={dataGroups}
                        search
                        onValueChange={(e) => handleChangeGroup(e.value)}
                        placeholder={"Phân khu"}
                    />
                </div>
                {isLoading ? (
                    <div>LOADING ...</div>
                ) : (
                    <>
                        <Pagination data={data} active={active} setActive={setActive}/>
                    </>
                )}
                <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-blue-500">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            STT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Dự án
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Phân khu
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Tòa
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Hành động</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>

                    {data?.data.map((item, index) => (
                        <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                            <td className="px-6 py-4">{index + 1}</td>
                            <td className="px-6 py-4">{item?.project_id?.project}</td>
                            <td className="px-6 py-4">{item?.group_id?.group_name}</td>
                            <td className="px-6 py-4">{item?.building_name}</td>
                            <td className="space-x-2 px-6 py-4 text-right">
                                <Link
                                    to={`/building/edit/${item._id}`}
                                    className="font-medium text-blue-600 hover:underline"
                                >
                                    Sửa
                                </Link>
                                <button
                                    className="font-medium text-red-600 hover:underline"
                                    onClick={() => handeDeleteBuilding(item._id)}
                                >
                                    Xóa
                                </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </>
    )
        ;
}

export default Building;
