import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link, useSearchParams} from "react-router-dom";
import * as TeleSerivce from "../../services/TeleService";
import {useQuery} from "react-query";
import {DateNew, DateTime} from "../../functions/CustomeDate";
import Pagination from "../Pagination/Pagination";
import {TESelect} from "tw-elements-react";
import * as UserService from "../../services/Userservice";
import {toast} from "react-toastify";
import Toast from "../Toast/Toast";

function CallAssignment() {
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const [user, setUser] = useState("");
    const [created_at, setCreated_at] = useState("");
    const userLogin = useSelector((state) => state.user);
    const {roles, access_token} = userLogin;
    const userRole = roles[0];
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const [active, setActive] = React.useState(1);
    const {isLoading: isLoadingUser, data: dataUser} = useQuery(
        ["users", access_token],
        () => UserService.getAllUser(access_token, "")
    );
    const {isLoading, data, isError, error} = useQuery(
        ["CallAssignment", page, access_token, user, created_at],
        () => TeleSerivce.getCallAssignment(page, access_token, user, created_at)
    );
    const [dataUsers, setDataUsers] = React.useState([]);

    const handleDeleteCallAssignment = async (id) => {
        if(window.confirm("Bạn có chắc chắn muốn xóa phân công gọi này không?")){
            const data = await TeleSerivce.deleteCallAssignment(id, access_token);
            if (data.success) {
                toastId.current = toast.success(data.message, Toastobjects);
            } else {
                toastId.current = toast.error("Xóa không thành công", Toastobjects);
            }

        }
    }
    useEffect(() => {
        if (dataUser?.data && dataUser?.data?.length > 0) {
            const users = dataUser?.data?.filter((item) => item.roles[0] != "sale");
            const newDataUsers = users?.map((item) => ({
                value: item._id,
                text: item.fullname,
            }));
            newDataUsers.unshift({value: "", text: "None"});
            setDataUsers(newDataUsers);
        }
    }, [dataUser]);

    return (
        <>
            <Toast/>
            <h1 className="my-3 text-start text-2xl font-semibold">
                Phân công cuộc gọi
            </h1>
            <div className="flex justify-end">
                {(userRole === "super_admin" || userRole === "admin") && (
                    <>
                        <div className="flex md:justify-end my-3">
                            <Link
                                to="/telesale/call-assignment/create"
                                className="px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
                            >
                                Tạo phân công gọi
                            </Link>
                        </div>
                    </>
                )}
            </div>
            <div className="grid sm:grid-cols-4 md:grid-cols-6 gap-1 mb-2 ">
                <div className="mb-5">
                    <label
                        htmlFor="user_name"
                        className="block mb-2 text-sm font-medium text-start text-gray-900"
                    >
                        Nhân viên
                    </label>
                    <TESelect
                        data={dataUsers}
                        search
                        placeholder={"Nhân viên"}
                        onValueChange={(e) => setUser(e.value)}
                    />
                </div>
                <div className="mb-5">
                    <label
                        htmlFor="user_name"
                        className="block mb-2 text-sm font-medium text-start text-gray-900"
                    >
                        Ngày tạo
                    </label>
                    <input
                        type="date"
                        id="created_at"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Ngày tạo"
                        onFocus={(e) => (e.target.type = "date")}
                        onBlur={(e) => (e.target.type = "text")}
                        onChange={(e) => setCreated_at(e.target.value)}
                    />
                </div>
            </div>

            {isLoading ? (
                <div>Loading</div>
            ) : (
                <Pagination data={data} active={active} setActive={setActive}/>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-blue-500">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            STT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Dự án
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Tòa nhà
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Trục căn
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Nhân viên
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Đã gọi
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Thời gian tạo
                        </th>
                        <th scope="col" className="px-3 py-3">
                            <span className="sr-only">Hành động</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <div>Loading</div>
                    ) : (
                        data?.data.map((item, index) => (
                            <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                                <td className="px-6 py-4">{index + 1}</td>
                                <td className="px-6 py-4">{item.project?.project}</td>
                                <td className="px-6 py-4">{item.building?.building_name}</td>
                                <td className="px-6 py-4">{item?.room?.join(",")}</td>
                                <td className="px-6 py-4">{item.user?.fullname}</td>
                                <td className="px-6 py-4">{item?.called}</td>
                                <td className="px-6 py-4">{DateTime(item.createdAt)}</td>
                                <td className="space-x-2 px-3 py-4 text-right">
                                    <a
                                        href="#"
                                        className={
                                            roles[0] == "manager"
                                                ? "hidden"
                                                : "font-medium text-red-600 hover:underline"
                                        }
                                        onClick={() => handleDeleteCallAssignment(item._id)}
                                    >
                                        Xóa
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CallAssignment;
