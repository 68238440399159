import React, { useEffect, useState } from "react";
import { useMutationHooks } from "../../hooks/useMutationHook";
import * as RealEstateService from "../../services/RealEstateService";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getDetailsById } from "../../functions/RealEstateFunction";
import { DateNew } from "../../functions/CustomeDate";
import { strToCapitalize } from "../../functions/strToCapitalize";
import { EditorComponent } from "../Editor/EditorComponent";

function EditLand() {
  const [landlord, setLandlord] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
  const [kindOf, setKindOf] = useState("");
  const [project, setProject] = useState("");
  const [loading, setLoading] = useState(true);
  const [building, setBuilding] = useState("");
  const [floor, setFloor] = useState("");
  const [room, setRoom] = useState("");
  const [acreage, setAcreage] = useState("");
  const [direction, setDirection] = useState("");
  const [interior, setInterior] = useState("");
  const [bedroomNumber, setBedRoomNumber] = useState("");
  const [priceSale, setPriceSale] = useState("");
  const [priceRent, setPriceRent] = useState("");
  const [note, setNote] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const userLogin = useSelector((state) => state.user);
  const { id: userId, access_token } = userLogin;
  const [isLoading1, setLoading1] = React.useState(true);
  const [isLoading2, setLoading2] = React.useState(true);
  const [dataCallAll, setData] = React.useState([]);
  const [dataCall, setDataTemp] = React.useState([]);
  const [dataCall1, setDataTemp1] = React.useState([]);
  const projectList = useSelector((state) => state.project);
  const { listProject } = projectList;

  const data = [{ text: "None", value: "" }];
  listProject.map((item) => {
    data.push({
      text: item.project,
      value: item._id,
      // value: "60f9f1b9c9e77c0015f0b4a5",
    });
  });

  useEffect(() => {
    if (!isLoading1) {
      const data = [{ text: "None", value: "" }];
      if (dataCall.data.length > 0) {
        dataCall?.data.map((item) => {
          data.push({
            text: item.group_name,
            value: item._id,
            // value: "60f9f1b9c9e77c0015f0b4a5",
          });
        });
      }
      // setLoading(true);
    }
    if (!isLoading2) {
      const data = [{ text: "None", value: "" }];
      if (dataCall1.data.length > 0) {
        dataCall1?.data.map((item) => {
          data.push({
            text: item.building_name,
            value: item._id,
            // value: "60f9f1b9c9e77c0015f0b4a5",
          });
        });
      }
    }
  }, [dataCall, isLoading1, isLoading2]);

  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const mutationEditLand = useMutationHooks(async (data) => {
    // const res = await TeleService.updateTeleSales(data);
    const res = await RealEstateService.updateRealEstate(
      realEstateId,
      data,
      access_token
    );
    return res;
  });

  const submitHandler = async (event) => {
    event.preventDefault();
    if (
      landlord === "" ||
      phoneNumberOne === "" ||
      kindOf === "" ||
      project === "" ||
      building === "" ||
      floor === "" ||
      room === "" ||
      acreage === "" ||
      direction === "" ||
      interior === "" ||
      bedroomNumber === ""
    ) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Không được để trống!", Toastobjects);
      }
    } else {
      //   const access_token = JSON.parse(localStorage.getItem("access_token"))
      await mutationEditLand.mutate({
        landlord,
        phone_number_1: phoneNumberOne,
        phone_number_2: phoneNumberTwo,
        kind_of: kindOf,
        project,
        // group,
        building,
        floor,
        room,
        acreage,
        direction: strToCapitalize(direction),
        interior,
        bedroom_number: bedroomNumber,
        price_sale: priceSale,
        price_rented: priceRent,
        note,
        status,
        expiration_date: date,
        userId: userId,
      });
    }
  };
  const { error, isLoading, isSuccess, isError } = mutationEditLand;
  const { id: realEstateId } = useParams();
  // const {
  //   isLoading: isLoadingGetRealEstate,
  //   data: dataGetRealEstate,
  //   isError: isErrorGetRealEstate,
  //   error: errorGetRealEstate,
  // } = useQuery(["realEstate", realEstateId, access_token], () =>
  //   getDetailsById(realEstateId, access_token)
  // );
  useEffect(() => {
    // if (isErrorGetRealEstate && errorGetRealEstate) {
    //   if (!toast.isActive(toastId.current)) {
    //     toastId.current = toast.error(
    //       error.response.data.message,
    //       Toastobjects
    //     );
    //   }
    // } else {

    // }
    const getData = async () => {
      const dataGetRealEstate = await getDetailsById(
        realEstateId,
        access_token
      );
      if (dataGetRealEstate) {
        setLandlord(dataGetRealEstate?.data.landlord);
        setPhoneNumberOne(dataGetRealEstate?.data.phone_number_1);
        setPhoneNumberTwo(dataGetRealEstate?.data.phone_number_2);
        setKindOf(dataGetRealEstate?.data.kind_of);
        setProject(dataGetRealEstate?.data?.project?._id);
        // setGroup(dataGetRealEstate?.data.group?._id);
        setBuilding(dataGetRealEstate?.data.building?._id);
        setFloor(dataGetRealEstate?.data.floor);
        setRoom(dataGetRealEstate?.data.room);
        setAcreage(dataGetRealEstate?.data.acreage);
        setDirection(dataGetRealEstate?.data.direction);
        setInterior(dataGetRealEstate?.data.interior);
        setBedRoomNumber(dataGetRealEstate?.data.bedroom_number?.toUpperCase());
        setPriceSale(dataGetRealEstate?.data.price_sale);
        setPriceRent(dataGetRealEstate?.data.price_rented);
        setNote(dataGetRealEstate?.data?.note);
        setStatus(dataGetRealEstate?.data.status);
        setDate(dataGetRealEstate?.data.expiration_date);
        setData(dataGetRealEstate);
        setLoading(false);
      }
      // setRoles(data?.data.roles[0]);
    };
    getData();
  }, []);

  useEffect(() => {
    if (!error && isSuccess) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(
          "Sửa bất động sản thành công",
          Toastobjects
        );
      }
    } else if (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
  }, [error, isSuccess]);
  return (
    <>
      <Toast />
      {loading ? (
        <div>Loading</div>
      ) : (
        <div className="w-full max-w-6xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
          <form className="space-y-3" action="#">
            <h1 className="text-2xl font-semibold">Sửa Bất động sản </h1>
            <h3 className="mb-3 text-center text-lg text-[#216cb4] font-medium">
              {dataCallAll?.data?.apartment} -{" "}
              {dataCallAll?.data?.project.project} -{" "}
              {dataCallAll?.data?.building.building_name} -{" "}
              {dataCallAll?.data?.bedroom_number}
            </h3>
            <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
              <div className="mb-5">
                <label
                  htmlFor="landlord"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Chủ nhà <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="landlord"
                  value={landlord}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Họ và tên"
                  onChange={(e) => setLandlord(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="phone_number_1"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  SĐT 1 <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="phone_number_1"
                  name="phone_number_1"
                  value={phoneNumberOne}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Số điện thoại"
                  onChange={(e) => setPhoneNumberOne(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  SĐT 2
                </label>
                <input
                  type="text"
                  id="phone_number_2"
                  name="phone_number_2"
                  value={phoneNumberTwo}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Số điện thoại"
                  onChange={(e) => setPhoneNumberTwo(e.target.value)}
                />
              </div>
            </div>
            <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
              <div className="mb-5">
                <label
                  htmlFor="acreage"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Diện tích (m2) <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="acreage"
                  name="acreage"
                  value={acreage}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Diện tích"
                  onChange={(e) => setAcreage(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="direction"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Hướng ban công <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="direction"
                  name="direction"
                  value={direction}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Hướng ban công"
                  onChange={(e) => setDirection(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="interior"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Nội thất <span className="text-red-600">(*)</span>
                </label>
                <select
                  id="interior"
                  name="interior"
                  value={interior}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  onChange={(e) => setInterior(e.target.value)}
                >
                  <option value="">Chọn nội thất</option>
                  <option selected={interior == 1} value="1">
                    Nội thất cơ bản
                  </option>
                  <option selected={interior == 2} value="2">
                    Full nội thất
                  </option>
                  <option selected={interior == 3} value="3">
                    Nguyên bản chủ đầu tư
                  </option>
                </select>
              </div>
            </div>
            <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
              <div className="mb-5">
                <label
                  htmlFor="room_number"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Số phòng ngủ <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="room_number"
                  name="room_number"
                  value={bedroomNumber}
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Số phòng ngủ"
                  onChange={(e) => setBedRoomNumber(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="price_sale"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Giá bán <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="price_sale"
                  name="price_sale"
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Giá bán"
                  value={priceSale}
                  onChange={(e) => setPriceSale(e.target.value)}
                />
              </div>
              <div className="mb-5">
                <label
                  htmlFor="price_rent"
                  className="block mb-2 text-sm font-medium text-start text-gray-900"
                >
                  Giá cho thuê <span className="text-red-600">(*)</span>
                </label>
                <input
                  type="text"
                  id="price_rent"
                  name="price_rent"
                  className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Giá cho thuê"
                  value={priceRent}
                  onChange={(e) => setPriceRent(e.target.value)}
                />
              </div>
            </div>
            {status == "on_rent" && (
              <>
                <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                  <div className="mb-5">
                    <label
                      htmlFor="price_rent"
                      className="block mb-2 text-sm font-medium text-start text-gray-900"
                    >
                      Hạn cho thuê
                    </label>
                    <input
                      type="date"
                      id="first_name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Hạn cho thuê"
                      value={DateNew(date).split("/").reverse().join("-")}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="lg:flex lg:justify-around">
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="dang_ban_dang_cho_thue"
                    type="radio"
                    name="status"
                    checked={status === "on_sale_rent"}
                    onChange={() => setStatus("on_sale_rent")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="dang_ban_dang_cho_thue"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Đang bán & Đang cho thuê
                </label>
              </div>
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="dang_ban"
                    type="radio"
                    name="status"
                    checked={status === "on_sale"}
                    onChange={() => setStatus("on_sale")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="dang_ban"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Đang bán
                </label>
              </div>
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="dang_cho_thue"
                    type="radio"
                    name="status"
                    checked={status === "on_rent"}
                    onChange={() => setStatus("on_rent")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="dang_cho_thue"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Đang cho thuê
                </label>
              </div>
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="da_ban"
                    type="radio"
                    name="status"
                    checked={status === "sold"}
                    onChange={() => setStatus("sold")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="da_ban"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Đã bán
                </label>
              </div>
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="da_cho_thue"
                    type="radio"
                    name="status"
                    checked={status === "rented"}
                    onChange={() => setStatus("rented")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="da_cho_thue"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Đã cho thuê
                </label>
              </div>
              <div className="flex items-start mb-5">
                <div className="flex items-center h-5">
                  <input
                    id="khong_nhu_cau"
                    type="radio"
                    name="status"
                    checked={status === "no_need"}
                    onChange={() => setStatus("no_need")}
                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                  />
                </div>
                <label
                  htmlFor="khong_nhu_cau"
                  className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Không nhu cầu
                </label>
              </div>
            </div>
            <div className="w-full">
              {<EditorComponent note={note} setNote={setNote} />}

              {/* <textarea
                            className="w-full max-w-5xl"
                            name="note"
                            id="note"
                            rows="5"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        ></textarea> */}
            </div>
            <button
              onClick={submitHandler}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Cập nhật thông tin
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default EditLand;
