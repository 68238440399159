import axios from "axios";
import {API} from "../utils/apiURL";

import jwt_decode from "jwt-decode";

export const loginUser = async (data) => {
    const res = await axios.post(`${API}/login`, data);
    return res.data;
};

export const getDetailsUser = async (id, access_token) => {
    try {
        const res = await axios.get(`${API}/user/${id}`, {
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        });
        return res.data;
    } catch (error) {
        return error;
    
        
    }

};
export const createdUsers = async (data, access_token) => {
    const res = await axios.post(`${API}/users`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};
export const getAllUser = async (access_token,name) => {
    const res = await axios.get(`${API}/users?fullname=${name}`, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};
export const getUserById = async (id, access_token) => {
    const res = await axios.get(`${API}/user/${id}`,{
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};

export const updateUser = async (data, access_token) => {
    //   const headers = {
    //     Authorization: `Bearer ${data.access_token}`,
    //   };
    const userId = data.id;
    delete data.id;
    const res = await axios.patch(`${API}/users/${userId}`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};

export const deleteUserById = async (id, access_token) => {
    const res = await axios.delete(`${API}/users/${id}`,{
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};

export const updateProfile = async (id, data, access_token) => {
    const res = await axios.patch(`${API}/users/${id}`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res
}

export const updatePassword = async (id, data, access_token) => {
    const res = await axios.patch(`${API}/users/password`, data, {
        headers: {
            Authorization: `Bearer ${access_token}`,
        },
    });
    return res.data;
};

