import { combineReducers } from "redux";
import sidebarReducer from "./sidebarReducer";
import statusReducer from "../features/statusSlice/statusSlice";
import userReducer from "../features/userSlice/userSlice";
import projectReducer from "../features/projectSlice/projectSlice";

import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["sidebar", "status"],
};
const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  status: statusReducer,
  user: userReducer,
  project: projectReducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export let persistor = persistStore(store);
