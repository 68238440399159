export const customHouse = (id) => {
    let result = (parseInt(id));
    let text = ''
    switch (result) {
        case 1:
            text = 'Căn hộ chung cư'
            break;
        case 2:
            text = 'Căn hộ penthouse'
            break;
        case 3:
            text = 'Căn hộ Duplex'
            break;
        case 4:
            text = 'Căn hộ Studio'
            break;
        case 5:
            text = 'OfficeTel'
            break;
        case 6:
            text = 'Biệt thự liền kề'
            break;
        case 7:
            text = 'ShopHouse'
            break;
        case 8:
            text = 'Căn hộ đập thông'
            break;
        default:
            text = ''
            break;
    }
    return text;
}