import {useSelector} from "react-redux";
import {getAll} from "../../functions/GroupFunctions";
import {getBuilding} from "../../functions/BuildingFunction";
import {TESelect} from "tw-elements-react";
import {useMutationHooks} from "../../hooks/useMutationHook";
import * as UserService from "../../services/Userservice";
import {useQuery} from "react-query";
import * as TeleService from "../../services/TeleService";
import * as ProjectService from "../../services/ProjectService";
import * as GroupService from "../../services/GroupService";
import * as BuildingService from "../../services/BuildingService";
import Toast from "../Toast/Toast";
import {toast} from "react-toastify";
import React, { useEffect } from "react";

function CreateCallAssignment() {
    const [project, setProject] = React.useState("");
    const [group, setGroup] = React.useState("");
    const [building, setBuilding] = React.useState("");
    const [room, setRoom] = React.useState("");
    const [userId, setUser] = React.useState();

    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const {isLoading, data, isError, error} = useQuery(
        ["users", access_token],
        () => UserService.getAllUser(access_token,"")
    );
    const [dataUsers, setDataUsers] = React.useState([]);

    useEffect(() => {
        if (data?.data && data?.data?.length > 0) {
            const users = data?.data?.filter((item) => item.roles[0] != "sale");
            const newDataUsers = users?.map((item) => ({
                value: item._id,
                text: item.fullname,
            }));
            newDataUsers.unshift({value: "", text: "None"});
            setDataUsers(newDataUsers);
        }
    }, [data]);

    const mutationAddCall = useMutationHooks(async (data) => {
        const res = await TeleService.callAssignment(data, access_token);
        return res;
    });

    const {isError: isErrorAddCall, isSuccess: isSuccessAddCall, error: errorAddCall} = mutationAddCall;
    const handleSubmit = async (e) => {
        e.preventDefault()
        const rooms=room.split(",");
        const resultRooms=rooms.map((item)=>item?.trim())
        await mutationAddCall.mutate({
            project,
            group,
            building,
            room: resultRooms,
            user: userId,
        });
    };

    useEffect(() => {
        if (!errorAddCall && isSuccessAddCall) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Phân công thành công!", Toastobjects);
            }
        } else if (isErrorAddCall) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Phân công thất bại!", Toastobjects);
            }
        }
    }, [isSuccessAddCall, isErrorAddCall]);

    const [dataProjects, setDataProjects] = React.useState([]);
    const [dataGroups, setDataGroups] = React.useState([]);
    const [dataBuilding, setDataBuilding] = React.useState([]);
    const {data: projects} = useQuery(["Project", access_token], () =>
        ProjectService.getGroupProject(access_token)
    );

    const mutationGetGroupByProjectId = useMutationHooks((data) => {
        const res = GroupService.getGroupByProjectId(data, access_token);
        return res;
    });

    const {
        error: errorGetGroupByProjectId,
        isSuccess: isSuccessGetGroupByProjectId,
        isError: isErrorGetGroupByProjectId,
        data: dataGetGroupByProjectId,
    } = mutationGetGroupByProjectId;
    const mutationGetBuildingByGroupId = useMutationHooks((id) => {
        const res = BuildingService.getAllBuilding(project, id, access_token);
        return res;
    });
    const {
        error: errorGetBuildingByGroupId,
        isSuccess: isSuccessGetBuildingByGroupId,
        isError: isErrorGetBuildingByGroupId,
        data: dataGetBuildingByGroupId,
    } = mutationGetBuildingByGroupId;
    const handleChangeProject = async (value) => {
        setProject(value);
        await mutationGetGroupByProjectId.mutate(value);
    };
    const handleChangeGroup = async (value) => {
        setGroup(value);
        await mutationGetBuildingByGroupId.mutate(value);
    };
    const handleChangeBuilding = (value) => {
        setBuilding(value);
    };

    //Cap nhat du lieu Project
    useEffect(() => {
        if (projects?.data && projects?.data?.length > 0) {
            const newDataProjects = projects?.data?.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({value: "", text: "None"});
            setDataProjects(newDataProjects);
        }
    }, [projects]);

    //Cap nhat du lieu Group theo Project Id
    useEffect(() => {
        if (
            dataGetGroupByProjectId?.data &&
            dataGetGroupByProjectId?.data?.length > 0
        ) {
            const newDataGroups = dataGetGroupByProjectId?.data?.map((item) => ({
                value: item._id,
                text: item.group_name,
            }));
            newDataGroups.unshift({value: "", text: "None"});
            setDataGroups(newDataGroups);
        }
    }, [dataGetGroupByProjectId]);

    //Cap nhat du lieu Building theo Group Id
    useEffect(() => {
        setDataBuilding([]);
        if (
            dataGetBuildingByGroupId?.data &&
            dataGetBuildingByGroupId?.data?.length > 0
        ) {
            const newDataBuilding = dataGetBuildingByGroupId?.data?.map((item) => ({
                value: item._id,
                text: item.building_name,
            }));
            newDataBuilding.unshift({value: "", text: "None"});
            setDataBuilding(newDataBuilding);
        }
    }, [dataGetBuildingByGroupId]);
    return (
        <>
            <Toast/>
            {isLoading ? (
                <div>Loading</div>
            ) : (
                <div
                    className="w-full max-w-6xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow xs:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                    <form className="space-y-3" action="#">
                        <h1 className="text-2xl font-semibold">Tạo phân công cuộc gọi</h1>
                        <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                            <div className="mb-5">
                                <label
                                    htmlFor="project"
                                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                                >
                                    Dự án
                                </label>
                                <TESelect
                                    data={dataProjects}
                                    search
                                    onClick={(e) => setGroup("")}
                                    onValueChange={(e) => handleChangeProject(e.value)}
                                    placeholder={"Dự án"}
                                />
                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="building"
                                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                                >
                                    Phân khu
                                </label>
                                <TESelect
                                    data={dataGroups}
                                    search
                                    // onClick={(e) => setBuilding("")}
                                    onValueChange={(e) => handleChangeGroup(e.value)}
                                    placeholder={"Phân khu"}
                                />
                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="building"
                                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                                >
                                    Tòa
                                </label>
                                <TESelect
                                    data={dataBuilding}
                                    search
                                    onValueChange={(e) => handleChangeBuilding(e.value)}
                                    placeholder={"Tòa"}
                                />
                            </div>
                        </div>
                        <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                            <div className="mb-5">
                                <label
                                    htmlFor="room"
                                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                                >
                                    Trục căn
                                </label>
                                <input
                                    type="text"
                                    id="room"
                                    name="room"
                                    className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    placeholder="Trục căn"
                                    onChange={(e) => setRoom(e.target.value)}
                                />
                            </div>
                            <div className="mb-5">
                                <label
                                    htmlFor="user_name"
                                    className="block mb-2 text-sm font-medium text-start text-gray-900"
                                >
                                    Nhân viên
                                </label>
                                <TESelect
                                    data={dataUsers}
                                    search
                                    placeholder={"Nhân viên"}
                                    onValueChange={(e) => setUser(e.value)}
                                />
                            </div>
                        </div>
                        <button
                            onClick={handleSubmit}
                            className="text-white bg-indigo-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Phân công
                        </button>
                    </form>
                </div>
            )}
        </>
    );
}

export default CreateCallAssignment;
