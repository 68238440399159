import React from "react";
import {useState} from "react";
import * as TeleService from "../../services/TeleService";
import {toast} from "react-toastify";
import Toast from "../Toast/Toast";
import {useMutationHooks} from "../../hooks/useMutationHook";
import {useEffect} from "react";
import {Select} from "../Select/Select";
import {useSelector} from "react-redux";
import {TESelect} from "tw-elements-react";
import {getAll, getAllByProjectName} from "../../functions/GroupFunctions";
import {getBuilding} from "../../functions/BuildingFunction";
import {useQuery} from "react-query";
import * as ProjectService from "../../services/ProjectService";
import * as RealEstateService from "../../services/RealEstateService";
import * as GroupService from "../../services/GroupService";
import * as BuildingService from "../../services/BuildingService";
import {strToCapitalize} from "../../functions/strToCapitalize";

function AddLand() {
    const [landlord, setLandlord] = useState("");
    const [phoneNumberOne, setPhoneNumberOne] = useState("");
    const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
    const [kindOf, setKindOf] = useState("");
    const [project, setProject] = useState("");
    const [group, setGroup] = useState("");
    const [building, setBuilding] = useState("");
    const [floor, setFloor] = useState("");
    const [room, setRoom] = useState("");
    const [acreage, setAcreage] = useState("");
    const [direction, setDirection] = useState("");
    const [interior, setInterior] = useState("");
    const [bedroomNumber, setBedRoomNumber] = useState("");
    const [priceSale, setPriceSale] = useState("");
    const [priceRent, setPriceRent] = useState("");
    const [note, setNote] = useState("");
    const [status, setStatus] = useState("");
    const [date, setDate] = useState("");
    const userLogin = useSelector((state) => state.user);
    const {id: userId, access_token} = userLogin;

    const [fileExcel, setFileExcel] = React.useState(null);
    const [importExcel, setImportExcel] = useState(false);
    //   const [direction, setDirectiond] = useState("");
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    const [dataProjects, setDataProjects] = React.useState([])
    const [dataGroups, setDataGroups] = React.useState([])
    const [dataBuilding, setDataBuilding] = React.useState([])
    const {data: projects} = useQuery(["Project", access_token], () => ProjectService.getGroupProject(access_token))

    const mutationGetGroupByProjectId = useMutationHooks((data) => {
        const res = GroupService.getGroupByProjectId(data, access_token);
        return res;
    })

    const {
        error: errorGetGroupByProjectId,
        isSuccess: isSuccessGetGroupByProjectId,
        isError: isErrorGetGroupByProjectId,
        data: dataGetGroupByProjectId
    } = mutationGetGroupByProjectId;
    const mutationGetBuildingByGroupId = useMutationHooks((id) => {
        const res = BuildingService.getAllBuilding(project, id, access_token);
        return res;
    })
    const {
        error: errorGetBuildingByGroupId,
        isSuccess: isSuccessGetBuildingByGroupId,
        isError: isErrorGetBuildingByGroupId,
        data: dataGetBuildingByGroupId
    } = mutationGetBuildingByGroupId;
    const handleChangeProject = async (value) => {
        setProject(value);
        await mutationGetGroupByProjectId.mutate(value);
    }
    const handleChangeGroup = async (value) => {
        setGroup(value);
        await mutationGetBuildingByGroupId.mutate(value);
    }
    const handleChangeBuilding = (value) => {
        setBuilding(value);
    }
    const mutationAddLand = useMutationHooks(async (data) => {
        const res = await TeleService.createdTeleSales(data);
        return res;
    });

    //Cap nhat du lieu Project
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects.data.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({value: "", text: "None"});
            setDataProjects(newDataProjects);
        }
    }, [projects])

    //Cap nhat du lieu Group theo Project Id
    useEffect(() => {
        if (dataGetGroupByProjectId?.data && dataGetGroupByProjectId.data.length > 0) {
            const newDataGroups = dataGetGroupByProjectId.data.map((item) => ({
                value: item._id,
                text: item.group_name,
            }));
            newDataGroups.unshift({value: "", text: "None"});
            setDataGroups(newDataGroups);
        }
    }, [dataGetGroupByProjectId])

    //Cap nhat du lieu Building theo Group Id
    useEffect(() => {
        setDataBuilding([])
        if (dataGetBuildingByGroupId?.data && dataGetBuildingByGroupId.data.length > 0) {
            const newDataBuilding = dataGetBuildingByGroupId.data.map((item) => ({
                value: item._id,
                text: item.building_name,
            }));
            newDataBuilding.unshift({value: "", text: "None"});
            setDataBuilding(newDataBuilding);
        }
    }, [dataGetBuildingByGroupId])

    const submitHandler = async (event) => {
        event.preventDefault();
        if (
            landlord === "" ||
            phoneNumberOne === "" ||
            kindOf === "" ||
            project === "" ||
            building === "" ||
            floor === "" ||
            room === "" ||
            acreage === "" ||
            direction === "" ||
            interior === "" ||
            bedroomNumber === ""
        ) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Không được để trống!", Toastobjects);
            }
        } else {
            //   const access_token = JSON.parse(localStorage.getItem("access_token"))
            await mutationAddLand.mutate({
                landlord,
                phone_number_1: phoneNumberOne,
                phone_number_2: phoneNumberTwo,
                kind_of: kindOf,
                project,
                group,
                building,
                floor,
                room: room?.toUpperCase(),
                acreage,
                direction: strToCapitalize(direction),
                interior,
                bedroom_number: bedroomNumber?.toUpperCase(),
                price_sale: priceSale,
                price_rented: priceRent,
                userId: userId,
                status: status
            });
        }
    };
    const {error, isLoading, isSuccess, isError} = mutationAddLand;
    useEffect(() => {
        if (!error && isSuccess) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Thêm mới bất động sản thành công",
                    Toastobjects
                );
            }
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        }
    }, [error, isSuccess]);

    const mutationImportFileExcel = useMutationHooks(async (formData, access_token) => {
        const res = await RealEstateService.importFileExcel(formData, access_token);
        return res;
    })
    const {
        isError: isErrorImportFileExcel,
        isSuccess: isSuccessImportFileExcel,
        error: errorImportFileExcel
    } = mutationImportFileExcel;

    const onChangeImportExcel = (e) => {
        const file = e.target.files[0];
        if (!file) return;
        setFileExcel(file);
    };

    const onHandlerImportExcel = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append("file", fileExcel);
        mutationImportFileExcel.mutate(formData, access_token);
    };

    useEffect(() => {
        if (!isErrorImportFileExcel && isSuccessImportFileExcel) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success(
                    "Import thành công bất động sản",
                    Toastobjects
                );
            }
        } else if (isErrorImportFileExcel) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    errorImportFileExcel.response.data.message,
                    Toastobjects
                );
            }
        }
    }, [isErrorImportFileExcel, isSuccessImportFileExcel]);

    return (
        <>
            <Toast/>
            <div
                className="w-full max-w-6xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-3">
                    <h1 className="text-2xl font-semibold">Tạo mới Bất động sản</h1>
                    <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                        <div className="mb-5">
                            <label
                                htmlFor="landlord"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Chủ nhà <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="landlord"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Họ và tên"
                                onChange={(e) => setLandlord(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="phone_number_1"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                SĐT 1 <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="phone_number_1"
                                name="phone_number_1"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Số điện thoại"
                                onChange={(e) => setPhoneNumberOne(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="email"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                SĐT 2
                            </label>
                            <input
                                type="text"
                                id="phone_number_2"
                                name="phone_number_2"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Số điện thoại"
                                onChange={(e) => setPhoneNumberTwo(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                        <div className="mb-5">
                            <label
                                htmlFor="project"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Dự án <span className="text-red-600">(*)</span>
                            </label>
                            <TESelect
                                data={dataProjects}
                                search
                                onClick={(e) => setGroup("")}
                                onValueChange={(e) => handleChangeProject(e.value)}
                                placeholder={"Dự án"}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="project"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Phân khu
                            </label>
                            <TESelect
                                data={dataGroups}
                                search
                                // onClick={(e) => setBuilding("")}
                                onValueChange={(e) => handleChangeGroup(e.value)}
                                placeholder={"Phân khu"}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="building"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Tòa <span className="text-red-600">(*)</span>
                            </label>
                            <TESelect
                                data={dataBuilding}
                                search
                                onValueChange={(e) => handleChangeBuilding(e.value)}
                                placeholder={"Tòa"}
                            />
                        </div>
                    </div>

                    <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                        <div className="mb-5">
                            <label
                                htmlFor="floor"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Tầng <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="floor"
                                name="floor"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Tầng"
                                onChange={(e) => setFloor(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="room"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Trục căn <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="room"
                                name="room"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Trục căn"
                                onChange={(e) => setRoom(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="acreage"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Diện tích (m2) <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="acreage"
                                name="acreage"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Diện tích"
                                onChange={(e) => setAcreage(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                        <div className="mb-5">
                            <label
                                htmlFor="direction"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Hướng ban công <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="direction"
                                name="direction"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Hướng ban công"
                                onChange={(e) => setDirection(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="interior"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Nội thất <span className="text-red-600">(*)</span>
                            </label>
                            <select
                                id="interior"
                                name="interior"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setInterior(e.target.value)}
                            >
                                <option selected>Chọn nội thất</option>
                                <option value="1">Nội thất cơ bản</option>
                                <option value="2">Full nội thất</option>
                                <option value="3">Nguyên bản chủ đầu tư</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="room_number"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Số phòng ngủ <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="acreage"
                                name="acreage"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Số phòng ngủ"
                                onChange={(e) => setBedRoomNumber(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                        <div className="mb-5">
                            <label
                                htmlFor="kind_of"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Loại bất động sản <span className="text-red-600">(*)</span>
                            </label>
                            <select
                                id="kind_of"
                                name="kind_of"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setKindOf(e.target.value)}
                            >
                                <option selected>Chọn loại BĐS</option>
                                <option value="1">Căn hộ chung cư</option>
                                <option value="2">Căn hộ penthouse</option>
                                <option value="3">Căn hộ Duplex</option>
                                <option value="4">Căn hộ Studio</option>
                                <option value="5">OfficeTel</option>
                                <option value="6">Biệt thự liền kề</option>
                                <option value="7">ShopHouse</option>
                                <option value="8">Căn hộ đập thông</option>
                            </select>
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="price_sale"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Giá bán <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="price_sale"
                                name="price_sale"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Giá bán"
                                onChange={(e) => setPriceSale(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="price_rent"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Giá cho thuê <span className="text-red-600">(*)</span>
                            </label>
                            <input
                                type="text"
                                id="price_rent"
                                name="price_rent"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Giá cho thuê"
                                onChange={(e) => setPriceRent(e.target.value)}
                            />
                        </div>
                    </div>
                    {status == "on_rent" &&
                        <>
                            <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
                                <div className="mb-5">
                                    <label
                                        htmlFor="price_rent"
                                        className="block mb-2 text-sm font-medium text-start text-gray-900"
                                    >
                                        Hạn cho thuê
                                    </label>
                                    <input
                                        type="date"
                                        id="first_name"
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                        placeholder="Hạn cho thuê"
                                        onFocus={(e) => (e.target.type = "date")}
                                        onBlur={(e) => (e.target.type = "text")}
                                        onChange={(e) => setDate(e.target.value)}
                                    />
                                </div>
                            </div>
                        </>
                    }

                    <div className="md:flex md:justify-around">
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="dang_ban_dang_cho_thue"
                                    type="radio"
                                    name="status"
                                    checked={status === "on_sale_rent"}
                                    onChange={() => setStatus("on_sale_rent")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="dang_ban_dang_cho_thue"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Đang bán & Đang cho thuê
                            </label>
                        </div>
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="dang_ban"
                                    type="radio"
                                    name="status"
                                    onChange={() => setStatus("on_sale")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="dang_ban"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Đang bán
                            </label>
                        </div>
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="dang_cho_thue"
                                    type="radio"
                                    name="status"
                                    onChange={() => setStatus("on_rent")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="dang_cho_thue"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Đang cho thuê
                            </label>
                        </div>
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="da_ban"
                                    type="radio"
                                    name="status"
                                    onChange={() => setStatus("sold")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="da_ban"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Đã bán
                            </label>
                        </div>
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="da_cho_thue"
                                    type="radio"
                                    name="status"
                                    onChange={() => setStatus("rented")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="da_cho_thue"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Đã cho thuê
                            </label>
                        </div>
                        <div className="flex items-start mb-5">
                            <div className="flex items-center h-5">
                                <input
                                    id="khong_nhu_cau"
                                    type="radio"
                                    name="status"
                                    onChange={() => setStatus("no_need")}
                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                                />
                            </div>
                            <label
                                htmlFor="khong_nhu_cau"
                                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Không nhu cầu
                            </label>
                        </div>
                    </div>
                    <div className="">
                        <label
                            htmlFor="note"
                            className="block mb-2 text-sm font-medium text-start text-gray-900"
                        >
                            Ghi chú
                        </label>
                        <textarea
                            className="w-full max-w-5xl"
                            name="note"
                            id="note"
                            rows="5"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                        ></textarea>
                    </div>

                    <div className="">
                        <label className="relative inline-flex items-center cursor-pointer">
                            <input
                                type="checkbox"
                                value={importExcel}
                                className="sr-only peer"
                                onClick={() => setImportExcel(!importExcel)}
                            />
                            <div
                                className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                Thêm BĐS bằng file Excel (.xlsx)
              </span>
                        </label>
                    </div>
                    {!importExcel && (
                        <>
                            <button
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                onClick={submitHandler}
                            >
                                Thêm BĐS
                            </button>
                        </>
                    )}
                    {importExcel && (
                        <>
                            <div className="">
                                <input
                                    className="mx-auto block text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    type="file"
                                    accept=".xlsx"
                                    onChange={(e) => onChangeImportExcel(e)}
                                />
                                <button
                                    className="mt-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    onClick={onHandlerImportExcel}
                                >
                                    IMPORT
                                </button>
                            </div>

                        </>
                    )}
                </form>
            </div>
        </>
    );
}

export default AddLand;
