import * as ProjectService from "../services/ProjectService";

export const getAll = async (access_token) => {
  const res = await ProjectService.getAllProject(access_token);
  return res;
};

export const getDetail = async (id, access_token) => {
  const res = await ProjectService.getDetail(id, access_token);
  return res;
};

export const deleteProjectById = async (id, access_token) => {
  const res = await ProjectService.deleteProjectById(id, access_token);
  return res;
};
