import React from "react"
import Land from "../../components/Land/Land";
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import {Link} from "react-router-dom";
import withAuthorization from "../../privateRouterv2";

function LandPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <Land/>
            </div>

        </>
    )
}

export default withAuthorization(LandPage, ["super_admin", "admin","manager"]);