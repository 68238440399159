import axios from "axios";
import { API } from "../utils/apiURL";

export const getAllBuilding = async (project, group, access_token,active) => {
  const res = await axios.get(
    `${API}/building?project_id=${project}&group_id=${group}&page=${active}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    }
  );
  return res.data;
};

export const getBuildingById = async (id, access_token) => {
  const res = await axios.get(`${API}/building/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};

export const createBuilding = async (data, access_token) => {
  const res = await axios.post(`${API}/building`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res;
};

export const editBuilding = async (id, data, access_token) => {
  const res = await axios.patch(`${API}/building/${id}`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res;
};

export const deleteBuildingById = async (id, access_token) => {
  const res = await axios.delete(`${API}/building/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res;
};
