import React, { useEffect } from "react";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast";
import * as UserService from "../../services/Userservice";
import { useMutationHooks } from "../../hooks/useMutationHook";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../features/userSlice/userSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { getAll } from "../../functions/projectFunction";
import { updateProject } from "../../features/projectSlice/projectSlice";
import logo from "./../../assets/img/CNT-Logo-Vien3.png"
import blink from "./../../assets/icon/icon-misc.svg"
import "./Login.css";

function Login() {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const userLogin = useSelector((state) => state.user);
  const { access_token } = userLogin;
  const toastId = React.useRef(null);
  const history = useNavigate();
  const dispatch = useDispatch();
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const mutationLoginUser = useMutationHooks(async (data) => {
    const res = await UserService.loginUser(data);
    return res;
  });
  const handleGetDetailsUser = async (id, token) => {
    const res = await UserService.getDetailsUser(id, token);
    // const resProject = await getAll(token);
    // dispatch(updateProject({ listProject: resProject.data }));
    dispatch(updateUser({ ...res?.data, access_token: token }));
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    const data = {
      username: username,
      password: password,
    };
    await mutationLoginUser.mutate(data);
    // dispatch(updateUser({ ...res?.data.data }));
  };

  const { error, isLoading, isSuccess, isError, data } = mutationLoginUser;

  useEffect(() => {
    if (!error && isSuccess) {
      //   console.log("🚀 ~ file: Login.jsx:45 ~ useEffect ~ data:", data);
      localStorage.setItem("access_token", JSON.stringify(data.token));
      if (data?.token) {
        const decoded = jwtDecode(data?.token);
        if (decoded?.user_id) {
          handleGetDetailsUser(decoded?.user_id, data?.token);
        }
        if (!toast.isActive(toastId.current)) {
          toastId.current = toast.success("Login Thành công", Toastobjects);
        }
      }
      //   if (!toast.isActive(toastId.current)) {
      //     toastId.current = toast.success("Login thành công", Toastobjects);
      //   }
      // history("/user");
    } else if (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
    if (access_token) {
      history("/");
    }
  }, [error, isSuccess, access_token]);
  return (
    <>
      <Toast />
      <div
        id="authentication-modal"
        tabIndex="-1"
        aria-hidden="true"
        className="bg-gradient-to-r from-[#E1DE6D] to-[#502F18] shadow-md overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-0rem)] max-h-full"

      >
        <div className="snowflkes" aria-hidden="true">
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
          <div className="snowflake"><img src={blink} alt=""/></div>
        </div>

        <div className="relative p-4 w-full max-w-md max-h-full mx-auto mt-5">

          <div className="relative bg-white rounded-lg shadow p-3">
            <div className="mb-8 flex flex-col items-center">
              <img className="mt-2" src={logo} width="150" alt=""
                   srcSet=""/>
              <h1 className="mt-2 text-2xl font-medium">Đăng nhập</h1>
            </div>
            {/*<div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">*/}
            {/*  <h3 className="mx-auto uppercase text-xl font-semibold text-white">*/}
            {/*    Đăng nhập*/}
            {/*  </h3>*/}
            {/*</div>*/}

            <div className="p-4 md:p-5">
              <form className="space-y-4">
                <div>
                  <label
                    htmlFor="username"
                    className="text-start block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Tài khoản
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    onChange={(e) => setUsername(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Tài khoản"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="text-start block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Mật khẩu
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Mật khẩu"
                    onChange={(e) => setPassword(e.target.value)}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  />
                </div>
                <button
                  onClick={handleLogin}
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Đăng nhập
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
