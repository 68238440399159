import React from "react"
import {useSelector} from "react-redux";
import Sidebar from "../../../components/Sidebar/Sidebar";
import withAuthorization from "../../../privateRouterv2";
import UpdateLandCallback from "../../../components/Telesale/Callback/UpdateLandCallback";

function UpdateLandPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return(
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <UpdateLandCallback/>
            </div>
        </>
    )
}

export default withAuthorization(UpdateLandPage, ["super_admin","telesale","admin"]);