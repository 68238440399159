import * as RealEstate from "../services/RealEstateService";

export const getAll = async (access_token,pageParams ,code,project,group,building,kindOf,apartment) => {
    const res = await RealEstate.getAll(access_token,pageParams,code,project,group,building,kindOf,apartment);
    return res;
};

export const getById = async (id) => {
    const res = await RealEstate.getDetail(id);
    return res;
};

export const updateRealEstate = async (id) => {
    const res = await RealEstate.updateRealEstate(id);
    return res;
}

export const getDetailsById = async (id, access_token) => {
    const res = await RealEstate.getDetailsById(id, access_token);
    return res;
};

export const getApporre = async () => {
  const res = await RealEstate.getApporre();
  return res;
};