import React from "react";
import Navbar from "./Navbar";

function Sale({ amountRequirePhone }) {
  return (
    <>
      <Navbar amountRequirePhone={amountRequirePhone} />
    </>
  );
}

export default Sale;
