import * as UserService from "../services/Userservice";

export const fetchUsers = async (access_token,name) => {
  const res = await UserService.getAllUser(access_token,name);
  return res;
};

export const fetchUserById = async (id, access_token) => {
  const res = await UserService.getUserById(id, access_token);
  return res;
};

export const deleteUserById = async (id, access_token) => {
  const res = await UserService.deleteUserById(id, access_token);
  return res;
};


