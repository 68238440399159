import React, {useEffect} from "react"
import {useSelector} from "react-redux";
import {useQuery} from "react-query";
import * as ProjectService from "../../services/ProjectService";
import * as GroupService from "../../services/GroupService";
import * as BuildingService from "../../services/BuildingService";
import {useMutationHooks} from "../../hooks/useMutationHook";
import {createBuilding} from "../../services/BuildingService";
import Toast from "../Toast/Toast";
import {TESelect} from "tw-elements-react";
import {toast} from "react-toastify";
function AddBuilding() {
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;

    const [project, setProject] = React.useState("");
    const [group, setGroup] = React.useState("");
    const [building, setBuilding] = React.useState("");

    const [dataProjects, setDataProjects] = React.useState([])
    const [dataGroups, setDataGroups] = React.useState([])

    const {data: projects} = useQuery(["Project", access_token], () => ProjectService.getGroupProject(access_token))

    const mutationAddBuilding = useMutationHooks((data) => {
        const res = BuildingService.createBuilding(data, access_token);
        return res;
    })

    const {error: errorAddBuilding, isSuccess: isSuccessAddBuilding, isError: isErrorAddBuilding, data: dataAddBuilding} = mutationAddBuilding;
    const submitHandler = async (e) => {
        e.preventDefault();
        const data = {
            project: project,
            group: group,
            building_name: building
        };
        await mutationAddBuilding.mutate(data);
    };

    const mutationGetGroupByProjectId = useMutationHooks((data) => {
        const res = GroupService.getGroupByProjectId(data, access_token);
        return res;
    })

    const {error: errorGetGroupByProjectId, isSuccess: isSuccessGetGroupByProjectId, isError: isErrorGetGroupByProjectId, data: dataGetGroupByProjectId} = mutationGetGroupByProjectId;

    const handleChangeProject = async (value) => {
        setProject(value);
        await mutationGetGroupByProjectId.mutate(value);
    }
    const handleChangeGroup = (value) => {
        setGroup(value);
    }

    //Cap nhat du lieu Project
    useEffect(() => {
        if (projects?.data && projects.data.length > 0) {
            const newDataProjects = projects.data.map((item) => ({
                value: item._id,
                text: item.project,
            }));
            newDataProjects.unshift({ value: "", text: "None" });
            setDataProjects(newDataProjects);
        }
    }, [projects])

    //Cap nhat du lieu Group theo Project Id
    useEffect(() => {
        if (dataGetGroupByProjectId?.data && dataGetGroupByProjectId.data.length > 0) {
            const newDataGroups = dataGetGroupByProjectId.data.map((item) => ({
                value: item._id,
                text: item.group_name,
            }));
            newDataGroups.unshift({ value: "", text: "None" });
            setDataGroups(newDataGroups);
        }
    }, [dataGetGroupByProjectId])

    useEffect(() => {
        if (!errorAddBuilding && isSuccessAddBuilding) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Thêm mới tòa thành công", Toastobjects
                );
            }
        } else if (errorAddBuilding) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Thêm tòa không thành công", Toastobjects);
            }
        }
    },[errorAddBuilding, isSuccessAddBuilding])

    return (
        <>
            <Toast/>
            <div
                className="w-full max-w-5xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
                <form className="space-y-6" action="#">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                        Thêm Tòa
                    </h5>
                    <div className="md:flex md:justify-center md:gap-2">
                        <div className="mb-5">
                            <label
                                htmlFor="project"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Dự án <span className="text-red-600">(*)</span>
                            </label>
                            <TESelect
                                data={dataProjects}
                                search
                                onValueChange={(e) => handleChangeProject(e.value)}
                                placeholder={"Dự án"}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="group"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Phân khu <span className="text-red-600">(*)</span>
                            </label>
                            <TESelect
                                data={dataGroups}
                                search
                                onValueChange={(e) => handleChangeGroup(e.value)}
                                placeholder={"Phân khu"}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="building"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Tòa{" "}
                            </label>
                            <input
                                type="text"
                                id="building"
                                onChange={(e) => setBuilding(e.target.value)}
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Tòa"
                            />
                        </div>
                    </div>
                    <button
                        onClick={submitHandler}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Thêm Tòa
                    </button>
                </form>
            </div>
        </>
    )
}

export default AddBuilding
