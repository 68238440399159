import axios from "axios";
import { API } from "../utils/apiURL";

export const createCallback = async (data, access_token) => {
  const res = await axios.post(`${API}/callbacks`, data, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getCallbacks = async (access_token) => {
  const res = await axios.get(`${API}/callbacks`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const getCallbackById = async (id, access_token) => {
  const res = await axios.get(`${API}/callbacks/${id}`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return res.data;
};
export const deleteCallbackById = async (id, access_token) => {
  try {
    const res = await axios.delete(`${API}/callbacks/${id}`, {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    return res.data;
  }catch (error) {
    console.log(error)
  }
}