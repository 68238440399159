import React, { useState } from "react";
import Pagination from "../Pagination/Pagination";
import Statisfical from "./Statistical";
import UpdateStatusLand from "./UpdateStatusLand";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getAll } from "../../functions/saleFunction";
import { useQuery } from "react-query";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Toast from "../../components/Toast/Toast";
import { customeDate } from "../../functions/CustomeDate";
import { useSelector } from "react-redux";

function Main() {
  const statusOrder = {
    no_approached: {
      title: "Chưa tiếp cận",
      count: 0,
      bg: "#17a2b8",
    },
    appointment: {
      title: "Hẹn xem nhà",
      count: 0,
      bg: "#FFC20E",
    },
    net: {
      title: "Khách nét",
      count: 0,
      bg: "#28a745",
    },

    no_net: {
      title: "Không nét",
      count: 0,
      bg: "#dc3545",
    },
    staked: {
      title: "Đặt cọc",
      count: 0,
      bg: "#1c8032",
    },
    contract: {
      title: "Ký hợp đồng",
      count: 0,
      bg: "#337ab7",
    },
    lost: {
      title: "Mất",
      count: 0,
      bg: "#babbbc",
    },
  };

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const [phoneCustomer, setPhoneCustomer] = useState("");
  const [Customer, setCustomer] = useState("");
  const [status1, setStatus] = useState("");
  const status = useSelector((state) => state.status);
  const [active, setActive] = React.useState(1);
  const { type } = status;
  const userLogin = useSelector((state) => state.user);
  const { id, access_token } = userLogin;
  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const { isLoading, data, isError, error } = useQuery(
    ["products", page, type, phoneCustomer, Customer, access_token],
    () => getAll(page, type, phoneCustomer, Customer, access_token)
  );

  useEffect(() => {
    if (isError && error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
  }, [isError, error]);

  return (
    <>
      <h1 className="mt-3 text-start text-2xl font-semibold">Danh sách đơn</h1>
      <Statisfical
        data={data?.data}
        totalNoApproached={data?.totalNoApproached}
        totalAppointment = {data?.totalAppointment} 
        totalNet={data?.totalNet}
        totalNoNet={data?.totalNoNet}
        totalStake={data?.totalStake}
        totalStaked={data?.totalStaked}
        totalContract={data?.totalContract}
        totalLost={data?.totalLost}
        // setStatus={setStatus}
        statusOrder={statusOrder}
      />

      <div className="flex justify-end">
        <Link
          to="/sale/booking"
          className="my-2 px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
        >
          Tạo đơn
        </Link>
      </div>
      {isLoading ? <div>Loading</div> : <Pagination data={data} active={active} setActive={setActive} />}

      <form className="mt-3">
        <div className="grid gap-1 mb-2 md:flex">
          <div>
            <input
              type="text"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="SĐT KH"
              onChange={(e) => setPhoneCustomer(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              id="last_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Nhân viên kinh doanh"
              onChange={(e) => setCustomer(e.target.value)}
            />
          </div>
          <div>
            <input
              type="date"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Gọi từ ngày"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          </div>
          <div>
            <input
              type="date"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Đến ngày"
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
            />
          </div>
          <button className="text-white bg-indigo-700 hover:bg-indigo-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Tìm kiếm
          </button>
        </div>
      </form>

      {isLoading ? (
        <div>Loading</div>
      ) : (
        data?.data.map((item) => (
          <div className="flex flex-col items-end bg-white border border-gray-200 rounded-lg shadow md:flex-row md:justify-between hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
            <div className="text-start p-4 leading-normal">
              <p className="capitalize text-[#216cb4] font-medium">
                {item?.fullname}
              </p>
              <p className="capitalize text-[#e49f02] font-medium">
                {item?.phone_number_1}
              </p>
              <p className="capitalize text-[#999] text-sm">
                Phụ trách:{" "}
                <span className="capitalize ml-1 text-[#e49f02]">
                  {item?.created_by?.fullname}
                </span>
              </p>
              <p className="capitalize text-[#999] text-sm">
                Dự án:{" "}
                <span className="capitalize ml-1 text-[#e49f02]">
                  {item?.project?.project}
                </span>
              </p>
              <p className="capitalize text-[#999] text-sm">
                Liên hệ cuối:{" "}
                <span className="capitalize ml-1 text-[#dc3545]">
                  {customeDate(item?.updatedAt)} ngày trước
                </span>
              </p>
            </div>
            <div className="flex gap-2 p-4 leading-normal">
              <UpdateStatusLand statusOrder={statusOrder} item={item} />
              <button className="px-3 py-2 rounded-lg font-medium bg-[#FFC20E]">
                {item?.phone_number}
              </button>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default Main;
