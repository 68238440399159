import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import * as CallbackService from "../../../services/CallbackService";
import {useQuery} from "react-query";
import {DateNew, DateTime} from "../../../functions/CustomeDate";
import * as UserService from "../../../services/Userservice";
import {toast} from "react-toastify";
import Toast from "../../Toast/Toast";

function CallBack() {
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const [user, setUser] = useState("");
    const [created_at, setCreated_at] = useState("");
    const [dataCallbacks, setDataCallbacks] = React.useState([]);

    const userLogin = useSelector((state) => state.user);
    const {roles, access_token} = userLogin;
    const userRole = roles[0];
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get("page") || 1;
    const [active, setActive] = React.useState(1);
    const {isLoading: isLoadingUser, data: dataUser} = useQuery(
        ["users", access_token],
        () => UserService.getAllUser(access_token, "")
    );
    const {isLoading, data, isError, error} = useQuery(
        ["Callback", access_token],
        () => CallbackService.getCallbacks(access_token)
    );
    const [dataUsers, setDataUsers] = React.useState([]);

    const handleDeleteCallback = async (id) => {
        if (window.confirm("Bạn có chắc chắn muốn xóa không?")) {
            const data = await CallbackService.deleteCallbackById(id, access_token);
            console.log(data)
            if (data.succces) {  // Kiểm tra mã trạng thái trả về từ API
                toastId.current = toast.success("Xóa thành công", Toastobjects);
            } else {
                toastId.current = toast.error("Xóa không thành công", Toastobjects);
            }
        }
    }

    useEffect(() => {
        if (dataUser?.data && dataUser?.data?.length > 0) {
            const users = dataUser?.data?.filter((item) => item.roles[0] != "sale");
            const newDataUsers = users?.map((item) => ({
                value: item._id,
                text: item.fullname,
            }));
            newDataUsers.unshift({value: "", text: "None"});
            setDataUsers(newDataUsers);
        }
    }, [dataUser]);

    useEffect(() => {
        if (data && data?.length > 0) {
            setDataCallbacks(data);
        }
    },[data])
    return (
        <>
            <Toast/>
            <h1 className="my-3 text-start text-2xl font-semibold">
                Danh sách căn cần gọi lại
            </h1>
            {isLoading && (
                <div>Loading</div>
            )}
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-blue-500">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            STT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Căn hộ
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Chủ hộ
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Số điện thoại
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Thời gian tạo
                        </th>
                        <th scope="col" className="px-3 py-3">
                            <span className="sr-only">Hành động</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                            <div>Loading</div>
                        ) :
                        (dataCallbacks?.length == 0 ? <tr><td colSpan="6" className="text-center">Không có căn cần gọi lại</td></tr> :
                                dataCallbacks?.map((item, index) => (
                                    <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                                        <td className="px-6 py-4">{index + 1}</td>
                                        <td className="px-6 py-4">{item?.realEstate?.apartment}</td>
                                        <td className="px-6 py-4">{item?.realEstate?.landlord}</td>
                                        <td className="px-6 py-4">{item?.realEstate?.phone_number_1}</td>
                                        <td className="px-6 py-4">{DateTime(item?.createdAt)}</td>
                                        <td className="space-x-2 px-3 py-4 text-right flex flex-col sm:flex-row">
                                            <a
                                                href={`/telesale/callback/${item._id}`}
                                                className={
                                                    roles[0] == "manager"
                                                        ? "hidden"
                                                        : "font-medium text-green-600 hover:underline"
                                                }
                                                // onClick={() => handleDeleteCallAssignment(item._id)}
                                            >
                                                Gọi lại
                                            </a>
                                            <button
                                                className={
                                                    roles[0] == "manager"
                                                        ? "hidden"
                                                        : "font-medium text-red-600 hover:underline"
                                                }
                                                onClick={() => handleDeleteCallback(item._id)}
                                            >
                                                Xóa
                                            </button>
                                        </td>
                                    </tr>
                                ))
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default CallBack;
