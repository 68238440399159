import React, { useEffect } from "react";
import { getApporre } from "../../functions/RealEstateFunction";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { DateNew, customeDate } from "../../functions/CustomeDate";
import { useMutationHooks } from "../../hooks/useMutationHook";
import * as RealEstateService from "../../services/RealEstateService";
import * as WareHouse from "../../services/TeleService";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Toast from "../Toast/Toast";

function RequirePhone() {
  const userLogin = useSelector((state) => state.user);
  const { access_token } = userLogin;
  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || 1;

  const { isLoading, data, isError, error } = useQuery(
    ["WarehouseRent", page],
    () => getApporre(page)
  );
  const toastId = React.useRef(null);
  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  const mutationApprove = useMutationHooks(async (data) => {
    const { id } = data;
    const res = await RealEstateService.updateApporre(id, access_token);
    return res;
  });
  const mutationCancelLand = useMutationHooks(async (data) => {
    const { id } = data;
    const res = await WareHouse.cancelRequire(id, access_token);
    return res;
  });
  const handleClickApporre = async (id) => {
    await mutationApprove.mutate({
      id,
    });
  };
  const {
    isLoading: isLoadingApprove,
    isError: isErrorApprove,
    error: errorApprove,
    isSuccess: isSuccessApprove,
  } = mutationApprove;
  const cancelHandler = async (id) => {
    await mutationCancelLand.mutate({
      id,
    });
  };
  const {
    isLoading: isLoadingCancel,
    isError: isErrorCancel,
    error: errorCancel,
    isSuccess: isSuccessCancel,
  } = mutationCancelLand;

  useEffect(() => {
    if (errorApprove) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          "Chấp nhận yêu cầu không thành công",
          Toastobjects
        );
      }
    } else if (isSuccessCancel && !errorApprove) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(
          "Chấp nhận yêu cầu thành công",
          Toastobjects
        );
      }
    }
  }, [isErrorApprove, isSuccessApprove, errorApprove]);
  useEffect(() => {
    if (errorCancel) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
            "Từ chối yêu cầu không thành công",
            Toastobjects
        );
      }
    } else if (isSuccessCancel && !errorCancel) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(
            "Từ chối yêu cầu thành công",
            Toastobjects
        );
      }
    }
  }, [isErrorCancel, isSuccessCancel, errorCancel]);
  return (
    <>
      <Toast />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                STT
              </th>
              <th scope="col" className="px-6 py-3">
                Mã tin
              </th>
              <th scope="col" className="px-6 py-3">
                Căn hộ
              </th>
              <th scope="col" className="px-6 py-3">
                Tên KH
              </th>
              <th scope="col" className="px-6 py-3">
                SĐT KH
              </th>
              <th scope="col" className="px-6 py-3">
                Sale
              </th>
              <th scope="col" className="px-6 py-3">
                Thời gian yêu cầu
              </th>
              <th scope="col" className="px-6 py-3">
                Duyệt
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Yêu cầu SĐT</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <div>isLoading</div>
            ) : (
              data?.data.map((item, index) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-4">{index}</td>
                  <td className="px-6 py-4">{item.code}</td>
                  <td className="px-6 py-4">{item.apartment}</td>
                  <td className="px-6 py-4">{item.landlord}</td>
                  <td className="px-6 py-4">{item.phone_number_1}</td>
                  <td className="px-6 py-4">{item?.user_required?.fullname}</td>
                  <td className="px-6 py-4">{DateNew(item.createdAt)}</td>
                  <td className="px-6 py-4 flex gap-2">
                    <button
                      onClick={() => handleClickApporre(item._id)}
                      className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                    >
                      Phê duyệt
                    </button>
                    <a
                      // href="#"
                      type={"button"}
                      onClick={() => cancelHandler(item._id)}
                      className="cursor-pointer font-medium text-red-600 dark:text-blue-500 hover:underline"
                    >
                      Từ chối
                    </a>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default RequirePhone;
