import React from "react"
import Sidebar from "../../components/Sidebar/Sidebar";
import {useSelector} from "react-redux";
import AddGroup from "../../components/Group/AddGroup";
import withAuthorization from "../../privateRouterv2";

function AddGroupPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <AddGroup/>
            </div>
        </>
    )
}

export default withAuthorization(AddGroupPage, ["super_admin", "admin"]);