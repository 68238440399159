export const customInterior = (id) => {
    let result = (parseInt(id));

    let text = ''
    switch (result) {
        case 1:
            text = 'Nội thất cơ bản'
            break;
        case 2:
            text = 'Full nội thất'
            break;
        case 3:
            text = 'Nguyên bản chủ đầu tư'
            break;
        default:
            text = ''
            break;
        // default statements
    }
    return text;

}