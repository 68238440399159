import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import WarehouseRent from "./WarehouseRent";
import WarehouseSale from "./WarehouseSale";
import RequirePhone from "./RequirePhone";
import Main from "./Main";
import RentalDeadline from "./RentalDeadline";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getApporre } from "../../functions/RealEstateFunction";

const allowRoles = ["super_admin", "admin"];
function Navbar({ amountRequirePhone }) {
  const [main, setMain] = useState(true);
  const [warehouseSale, setWarehouseSale] = useState(false);
  const [warehouseRent, setWarehouseRent] = useState(false);
  const [rentalDeadline, setRentalDeadline] = useState(false);
  const [requirePhone, setRequirePhone] = useState(false);
  const [report, setReport] = useState(false);
  const userLogin = useSelector((state) => state.user);
  const { roles } = userLogin;
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;

  const handleFalse = () => {
    setMain(false);
    setWarehouseSale(false);
    setWarehouseRent(false);
    setRentalDeadline(false);
    setRequirePhone(false);
    setReport(false);
  };
  const handleMain = () => {
    handleFalse();
    setMain(true);
    // setStatus("");
  };
  const handleWarehouseSale = () => {
    handleFalse();
    setWarehouseSale(true);
  };
  const handleWarehouseRent = () => {
    handleFalse();
    setWarehouseRent(true);
  };
  const handleRentalDeadline = () => {
    handleFalse();
    setRentalDeadline(true);
  };
  const handleRequirePhone = () => {
    handleFalse();
    setRequirePhone(true);
  };
  const handleReport = () => {
    handleFalse();
    setReport(true);
  };

  const { isLoading, data, isError, error } = useQuery(
    ["WarehouseRent", page],
    () => getApporre(page)
  );
  return (
    <>
      <nav className="flex">
        <div className="inline-flex rounded-md shadow-sm">
          <button
            href="#"
            aria-current="page"
            onClick={handleMain}
            className={
              main
                ? "px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                : "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            }
          >
            Tất cả
          </button>
          <button
            href="#"
            onClick={handleWarehouseSale}
            className={
              warehouseSale
                ? "px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                : "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            }
          >
            Kho bán
          </button>
          <button
            href="#"
            onClick={handleWarehouseRent}
            className={
              warehouseRent
                ? "px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                : "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            }
          >
            Kho cho thuê
          </button>
          <button
            href="#"
            onClick={handleRentalDeadline}
            className={
              rentalDeadline
                ? "px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                : "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            }
          >
            Hạn cho thuê
          </button>
          {allowRoles.includes(roles[0]) && (
            <button
              href="#"
              onClick={handleRequirePhone}
              className={
                requirePhone
                  ? "pl-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                  : "pl-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 "
              }
            >
              <div className="relative h-full flex items-center pr-6">
                <span className="my-auto">Danh sách yêu cầu SĐT</span>
                {data?.data?.length > 0 && (
                  // <span className="absolute top-0 right-0 rounded-full px-1 bg-red-500 text-white font-medium">100</span>
                  <span className="absolute top-0 right-0 rounded-full px-1 bg-red-500 text-white font-medium ">
                    {data?.data?.length}
                  </span>
                )}
              </div>
            </button>
          )}
          <button
            href="#"
            onClick={handleReport}
            className={
              report
                ? "px-4 py-2 text-sm font-medium text-blue-700 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700"
                : "px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
            }
          >
            Báo cáo
          </button>
        </div>
      </nav>
      {main && <Main />}
      {warehouseRent && <WarehouseRent />}
      {warehouseSale && <WarehouseSale />}
      {rentalDeadline && <RentalDeadline />}
      {requirePhone && <RequirePhone amountRequirePhone={amountRequirePhone} />}
    </>
  );
}

export default Navbar;
