import * as TeleSaleService from "../services/TeleService";

export const getAll = async (access_token) => {
  const res = await TeleSaleService.getTeleSales(access_token);
  return res;
};

// export const fetchSaleById = async (id) => {
//   const res = await SaleService.getSaleById(id);
//   return res;
// };

// export const deleteSaleById = async (id) => {
//   const res = await SaleService.deleteSaleById(id);
//   return res;
// };
