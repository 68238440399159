import React from "react"
import Sidebar from "../../components/Sidebar/Sidebar";
import {useSelector} from "react-redux";
import Group from "../../components/Group/Group";
import withAuthorization from "../../privateRouterv2";

function GroupPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <Group/>
            </div>
        </>
    )
}

export default withAuthorization(GroupPage, ["super_admin", "admin"]);
