import React from "react";
import Booking from "../../components/Sale/Booking";
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import withAuthorization from "../../privateRouterv2";

function BookingPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <Booking/>
            </div>
        </>
    );
}

export default withAuthorization(BookingPage, [
    "super_admin",
    "admin",
    "manager",
    "sale",
    "intern",
]);
