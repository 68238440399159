export const notiNewRealEstate = (createdAt) => {
    const now = new Date();
    const created = new Date(createdAt);
    const diff = now - created;
    const diffInHours = diff / (1000 * 3600);
    if (diffInHours < 36) {
        return true;
    } else {
        return false;
    }
}