import React from "react";
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import {useEffect} from "react";
import {toast} from "react-toastify";
import Toast from "../../components/Toast/Toast";
import {deleteUserById, fetchUsers} from "../../functions/userFunction";
import Pagination from "../Pagination/Pagination";
import {useSelector} from "react-redux";
import {TESelect} from "tw-elements-react";

function Main() {
    const toastId = React.useRef(null);
    const userLogin = useSelector((state) => state.user);
    const {access_token} = userLogin;
    const [name, setName] = React.useState("");
    const [active, setActive] = React.useState(1);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const {isLoading, data, isError, error} = useQuery(
        ["Users", access_token, name],
        async () => {
            try {
                const result = await fetchUsers(access_token, name);
                return result;
            } catch (error) {
                throw error; // Re-throw the error to let the useQuery hook handle it
            }
        }
    );

    const handeDeleteUser = async (id) => {
        if (window.confirm("Bạn muốn xóa user?") == true) {
            const data = await deleteUserById(id, access_token);
            if (data.success) {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.success(data.message, Toastobjects);
                }
                window.location.reload();
            } else {
                if (!toast.isActive(toastId.current)) {
                    toastId.current = toast.error(data.message, Toastobjects);
                }
            }
        }
    };
    useEffect(() => {
        if (!isError && error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error("Có lỗi về phân quyền", Toastobjects);
            }
        }
    }, [isError, error]);
    return (
        <>
            <Toast/>
            <h1 className="mt-3 text-start text-2xl font-semibold">
                Danh sách nhân viên
            </h1>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <div className="flex sm:justify-end my-3">
                    <Link
                        to="/user/add"
                        className="px-3 py-2 rounded-lg font-medium text-white bg-[#008d4c] hover:bg-[#5cb85c]"
                    >
                        Thêm mới Nhân viên
                    </Link>
                </div>
                <div className="grid sm:grid-cols-4 md:grid-cols-6 gap-1 mb-2 ">
                    <div>
                        <input
                            type="text"
                            id="name"
                            onChange={(e) => setName(e.target.value)}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Tên nhân viên"
                        />
                    </div>
                </div>

                {isLoading ? (
                    <div>Loading</div>
                ) : (
                    <Pagination data={data ? data : []} active={active} setActive={setActive}/>
                )}
                <table className="mt-1 w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase bg-blue-500">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            STT
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Họ và tên
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Bộ phận
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Vị trí
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Quyền
                        </th>
                        <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Hành động</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        <div>Loading</div>
                    ) : (
                        data?.data.map((item, index) => (
                            <tr className="bg-white border-b hover:bg-gray-50 font-medium">
                                <td className="px-6 py-4">{index + 1}</td>
                                <td className="px-6 py-4">{item.fullname}</td>
                                <td className="px-6 py-4">{item.department}</td>
                                <td className="px-6 py-4">{item.position}</td>
                                <td className="px-6 py-4">{item.roles}</td>
                                <td className="space-x-2 px-6 py-4 text-right">
                                    <Link
                                        to={`/user/edit/${item._id}`}
                                        className="font-medium text-blue-600 hover:underline"
                                    >
                                        Sửa
                                    </Link>
                                    <a
                                        href="#"
                                        className="font-medium text-red-600 hover:underline"
                                        onClick={() => handeDeleteUser(item._id)}
                                    >
                                        Xóa
                                    </a>
                                </td>
                            </tr>
                        ))
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Main;
