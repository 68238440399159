export const mahoaCode = (room) => {
    const indexToReplace = room.length - 3;
    const modifiedRoom = room.substring(0, indexToReplace) + "x" + room.substring(indexToReplace + 1);
    return modifiedRoom;
}

export const mahoaTang = (floor) => {
    const indexToReplace = floor.length - 1;
    const modifiedRoom = floor.substring(0, indexToReplace) + "x" + floor.substring(indexToReplace + 1);
    return modifiedRoom;
}