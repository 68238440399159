import React from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import { saveAs } from 'file-saver';

import { DateNew, DateTime } from "../../functions/CustomeDate";
import { Link } from "react-router-dom";

function Gallery(props) {
  const { data } = props;
  const dataImg = data?.image_links;
  const dataSlider = dataImg?.map((item) => ({ src: item }));

  const maxImagesToShow = 6;
  const [lightboxOpen, setLightboxOpen] = React.useState(false);
  const [lightboxIndex, setLightboxIndex] = React.useState(0);

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };
  const visibleImages = dataImg.slice(0, maxImagesToShow);
  const remainingImages = dataImg.length - maxImagesToShow;
  // const downloadAllImages = () => {
  //   visibleImages.forEach((image, index) => {
  //     const link = document.createElement("a");
  //     link.href = image;
  //     link.download = `image-${index + 1}.jpg`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   });
  // };
  const downloadAllImages = () => {
    visibleImages.forEach((image, index) => {
      saveAs(image, `image-${index + 1}.jpg`);
    });
  };

  return (
    <>
      <div className="w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="mx-auto relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="p-4 md:p-5 border-b rounded-t dark:border-gray-600 flex justify-between">
              <div>
                <div className="flex items-center text-sm">
                  <div className="mr-1">
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white rounded-full"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                  </div>
                  <a className="font-semibold text-[#3B5998] capitalize hover:underline hover:decoration-1">
                    {data?.userId?.fullname}
                  </a>
                  <span className="ml-1 text-[#616770]">up ảnh căn hộ</span>

                  {
                    data?.realEstate?.status === "sold"?
                        <Link className="ml-1 text-[#3B5998] capitalize hover:underline hover:decoration-1">
                          {data?.realEstate?.apartment} (đã bán)
                        </Link> :
                        <Link className="ml-1 text-[#3B5998] capitalize hover:underline hover:decoration-1">
                          {data?.realEstate?.code}
                        </Link>
                  }
                  <span className="ml-1 text-[#616770]">tại dự án</span>
                  <Link className="ml-1 text-[#3B5998] capitalize hover:underline hover:decoration-1">
                    {data?.realEstate?.project?.project}
                  </Link>
                </div>
                <div className="ml-1 flex items-center gap-1 text-start text-sm ">
                  <svg
                    className="w-4 h-4 text-gray-800 dark:text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
                  </svg>
                  <p className="text-[#999999]">{DateTime(data?.createdAt)}</p>
                </div>
              </div>
              <div>
                <button
                  className="bg-blue-500 text-white font-semibold py-2 px-4 rounded hover:bg-blue-700"
                  onClick={downloadAllImages}
                >
                  Tải ảnh
                </button>
              </div>
            </div>

            <div className="mt-1 mx-auto w-[95%] grid grid-cols-2 md:grid-cols-3 gap-[1px]">
              {visibleImages.map((item, index) => (
                <div key={index} className="relative h-[175px] w-full ">
                  <button onClick={() => openLightbox(index)}>
                    <img
                      className="w-[300px] h-[175px] rounded-lg object-cover"
                      src={item}
                      alt=""
                    />
                  </button>
                  {index === 5 && (
                    <>
                      <button
                        className="absolute right-0 top-0 rounded-lg w-full h-full bg-gray-700/60"
                        onClick={() => openLightbox(maxImagesToShow)}
                      />
                      <span className="absolute text-white text-3xl transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 font-semibold">
                        +{remainingImages}
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Lightbox
        open={lightboxOpen}
        index={lightboxIndex}
        close={() => setLightboxOpen(false)}
        plugins={[Thumbnails]}
        slides={dataSlider}
      />
    </>
  );
}

export default Gallery;
