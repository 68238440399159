import React from "react";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import Toast from "../../components/Toast/Toast";
import {useEffect} from "react";
import {useQuery} from "react-query";
import {fetchUserById} from "../../functions/userFunction";
import {useState} from "react";
import {useMutationHooks} from "../../hooks/useMutationHook";
import * as UserService from "../../services/Userservice";
import {useSelector} from "react-redux";

function EditUser() {
    const {id} = useParams();
    const [fullname, setFullName] = useState("");
    const [username, setUsername] = useState("");
    const [position, setPosition] = useState("");
    const [department, setDepartment] = useState("");
    const [roles, setRoles] = useState([]);

    const userLogin = useSelector((state) => state.user);
    const { id: userId, access_token } = userLogin;
    const toastId = React.useRef(null);
    const Toastobjects = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };
    const mutation = useMutationHooks(async (data) => {
        //   const { id, access_token, ...rests } = data;
        await UserService.updateUser(data, access_token);
    });
    const handleUpdate = async (e) => {
        e.preventDefault();
        await mutation.mutate({
            id: id,
            fullname,
            department,
            position,
            roles,
        });
        // alert("Click");
        // mutation.mutate(decoded?.id, { phone, name, text, sex })
    };
    const {
        error: errorUpdate,
        isLoading: isLoadingUpdate,
        isError: isErrorUpdate,
        isSuccess,
    } = mutation;

    const {isLoading, data, isError, error} = useQuery(["user", id], () =>
        fetchUserById(id,access_token)
    );

    useEffect(() => {
        if (isError && error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        } else {
            setFullName(data?.data.fullname);
            setDepartment(data?.data.department);
            setPosition(data?.data.position);
            setRoles(data?.data.roles[0]);
        }
    }, [isError, error, data]);
    useEffect(() => {
        if (!error && isSuccess) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.success("Thành công!", Toastobjects);
            }
        } else if (error) {
            if (!toast.isActive(toastId.current)) {
                toastId.current = toast.error(
                    error.response.data.message,
                    Toastobjects
                );
            }
        }
    }, [error, isSuccess]);
    return (
        <>
            <Toast/>
            <div
                className="w-full max-w-4xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
                <form className="space-y-6">
                    <h5 className="text-xl font-medium text-gray-900 dark:text-white">
                        Sửa thông tin người dùng
                    </h5>
                    <div className="md:flex md:justify-center md:gap-2">
                        <div className="mb-5">
                            <label
                                htmlFor="text"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Họ và tên
                            </label>
                            <input
                                type="text"
                                id="name"
                                value={fullname}
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Họ và tên"
                                onChange={(e) => setFullName(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="department"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Bộ phận
                            </label>
                            <input
                                type="text"
                                id="department"
                                value={department}
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Bộ phận"
                                onChange={(e) => setDepartment(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="position"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Vị trí
                            </label>
                            <input
                                type="text"
                                id="position"
                                value={position}
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Vị trí"
                                onChange={(e) => setPosition(e.target.value)}
                            />
                        </div>
                        <div className="mb-5">
                            <label
                                htmlFor="roles"
                                className="block mb-2 text-sm font-medium text-start text-gray-900"
                            >
                                Phân quyền
                            </label>
                            <select
                                id="roles"
                                className="xs:w-full md:w-fit bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                onChange={(e) => setRoles(e.target.value)}
                            >
                                <option value="">Chọn phân quyền</option>
                                <option selected={roles == "super_admin"} value="super_admin">Super Admin</option>
                                <option selected={roles == "admin"} value="admin">Admin</option>
                                <option selected={roles == "admin"} value="manager">Manager</option>
                                <option selected={roles == "sale"} value="sale">Sale</option>
                                <option selected={roles == "telesale"} value="telesale">Telesale</option>
                                <option selected={roles == "intern"} value="intern">Thử việc</option>
                            </select>
                        </div>
                    </div>
                    <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={handleUpdate}
                    >
                        Sửa thông tin
                    </button>
                </form>
            </div>
        </>
    );
}

export default EditUser;
