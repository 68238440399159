import React, { useEffect, useState } from "react";
import { TESelect } from "tw-elements-react";
import { useSelector } from "react-redux";
import { getById } from "../../functions/RealEstateFunction";
import axios from "axios";
import * as RealEstateService from "../../services/RealEstateService";
import { useMutationHooks } from "../../hooks/useMutationHook";
import { toast } from "react-toastify";
import Toast from "../Toast/Toast";
import { useQuery } from "react-query";
import * as ProjectService from "../../services/ProjectService";
function UploadImage() {
  const [project, setProject] = React.useState("");
  const [apartment, setApartment] = React.useState("");
  const [dataProjects, setDataProjects] = React.useState([]);
  const [dataApartment, setDataApartment] = useState([]);
  const [images, setImages] = useState([]);
  const [loadingAddImg, setLoadingAddImg] = useState(false);
  const userLogin = useSelector((state) => state.user);
  const { access_token, id } = userLogin;
  const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);

  const toastId = React.useRef(null);

  const Toastobjects = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const { data: projects } = useQuery(["Project", access_token], () =>
    ProjectService.getGroupProject(access_token)
  );

  const mutationAddImg = useMutationHooks((data) => {
    const { realEstate, access_token, ...rests } = data;
    const res = RealEstateService.UpdateImg(realEstate, rests, access_token);
    return res;
  });
  const mutationGetApartmentByProjectId = useMutationHooks((id) => {
    const res = RealEstateService.getDetail(id, access_token);
    return res;
  });
  const {
    error: errorGetApartmentByProjectId,
    isSuccess: isSuccessGetApartmentByProjectId,
    isError: isErrorGetApartmentByProjectId,
    data: dataGetApartmentByProjectId,
    isLoading: isLoadingGetApartmentByProjectId,
  } = mutationGetApartmentByProjectId;
  const handleFileInputChange = (event) => {
    const selectedImages = Array.from(event.target.files);
    setImages(selectedImages);
  };

  const submitHandler = async (event) => {
    event.preventDefault();
    if (project === "" || apartment === "") {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error("Không được để trống!", Toastobjects);
      }
    } else {
      const uploadedImageUrls = [];

      try {
        // for (const image of images) {
        //   const formData = new FormData();
        //   formData.append("file", image);
        //   formData.append("upload_preset", "Project1");
        //
        //   const response = await axios.post(
        //     `https://api.cloudinary.com/v1_1/dgeeyhyzq/image/upload`,
        //     formData
        //   );
        //   // uploadedImageUrls.push(response.data.secure_url);
        //   return response.data.secure_url;
        // }

        const uploadImages = images.map(async (image) => {
            const formData = new FormData();
            formData.append("file", image);
            formData.append("upload_preset", "Project1");

            const response = await axios.post(
              `https://api.cloudinary.com/v1_1/dgeeyhyzq/image/upload`,
              formData
            );
            // uploadedImageUrls.push(response.data.secure_url);
            return response.data.secure_url;
        });

        try {
          setLoadingAddImg(true)
          const uploadedImageUrls = await Promise.all(uploadImages);
          await mutationAddImg.mutate({
            realEstate:apartment,
            image_links: uploadedImageUrls,
            userId: id,
            access_token,
          });
          setLoadingAddImg(false)
        }catch (error) {
            console.log(error);
        }
      } catch (error) {
        console.log(error);
      }

    }
  };
  const { error, isLoading: LoadingAdd, isSuccess, isError, data:dataAddImg } = mutationAddImg;
  const handleChangeProject = async (value) => {
    setProject(value);
    await mutationGetApartmentByProjectId.mutate(value);
  };

  useEffect(() => {
    if (projects?.data && projects.data.length > 0) {
      const newDataProjects = projects.data.map((item) => ({
        value: item._id,
        text: item.project,
      }));
      newDataProjects.unshift({ value: "", text: "None" });
      setDataProjects(newDataProjects);
    }
  }, [projects]);
  useEffect(() => {
    if (
      dataGetApartmentByProjectId?.data &&
      dataGetApartmentByProjectId?.data?.length > 0
    ) {
      const newDataApartment = dataGetApartmentByProjectId.data.map((item) => ({
        value: item._id,
        text: item.apartment,
      }));
      newDataApartment.unshift({ value: "", text: "None" });
      setDataApartment(newDataApartment);
    }
  }, [dataGetApartmentByProjectId?.data?.length]);
  useEffect(() => {
    if (!error && isSuccess) {
      setLoadingAddImg(false)
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.success(
          "Thêm mới ảnh căn hộ thành công",
          Toastobjects
        );
      }
    } else if (error) {
      if (!toast.isActive(toastId.current)) {
        toastId.current = toast.error(
          error.response.data.message,
          Toastobjects
        );
      }
    }
  }, [error, isSuccess]);

  return (
    <>
      <Toast />
      {loadingAddImg &&
          <>
            <div
                className="absolute w-screen h-screen z-10 bg-gray-50/60 border border-gray-200 rounded-lg">
              <div role="status" className={isSidebarOn?"relative left-1/3 top-1/3":"relative left-1/2 top-1/3"}>
                <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                     viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"/>
                  <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"/>
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </>
      }
      <div className="w-full max-w-6xl mx-auto p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8 dark:bg-gray-800 dark:border-gray-700">
        <form className="space-y-3" action="#">
          <h1 className="text-2xl font-semibold">Ảnh căn hộ</h1>
          <div className="md:flex md:justify-center md:gap-2 md:border-b-2">
            <div className="mb-5">
              <label
                htmlFor="project"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Dự án
              </label>
              <TESelect
                data={dataProjects}
                search
                onValueChange={(e) => handleChangeProject(e.value)}
                placeholder={"Dự án"}
              />
            </div>
            <div className="mb-5">
              <label
                htmlFor="room"
                className="block mb-2 text-sm font-medium text-start text-gray-900"
              >
                Căn hộ
              </label>
              <TESelect
                data={dataApartment}
                search
                onValueChange={(e) => setApartment(e.value)}
                placeholder={"Căn hộ"}
              />
            </div>
            <div className="mb-5">
              <label
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                htmlFor="images"
              >
                Ảnh căn hộ
              </label>
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="images"
                type="file"
                onChange={handleFileInputChange}
                multiple
              />
            </div>
          </div>
          <button
            onClick={submitHandler}
            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Thêm ảnh căn hộ
          </button>
        </form>
      </div>
    </>
  );
}

export default UploadImage;
