import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  fullname: "",
  phone_number: "",
  email: false,
  position: "",
  department: "",
  roles: "unknown",
  id: "",
  access_token: "",
};

export const userSlide = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser: (state, action) => {
      const {
        username = "",
        fullname,
        email = "",
        _id = "",
        phone_number = "",
        position,
        department,
        roles,
        access_token,
      } = action.payload;
      state.username = username;
      state.fullname = fullname;
      state.phone_number = phone_number;
      state.email = email;
      state.position = position;
      state.roles = roles;
      state.id = _id;
      state.department = department;
      state.access_token = access_token;
    },
    resetUser: (state) => {
      state.username = "";
      state.fullname = "";
      state.phone_number = "";
      state.email = "";
      state.position = "";
      state.roles = "";
      state.id = "";
      state.department = "";
      state.access_token = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUser, resetUser } = userSlide.actions;

export default userSlide.reducer;
