import React from "react"
import {useSelector} from "react-redux";
import Sidebar from "../../components/Sidebar/Sidebar";
import EditGroup from "../../components/Group/EditGroup";
import withAuthorization from "../../privateRouterv2";

function EditGroupPage() {
    const isSidebarOn = useSelector((state) => state.sidebar.isSidebarOn);
    return (
        <>
            <Sidebar/>
            <div className={isSidebarOn ? "px-4 xs:hidden sm:block sm:ml-64" : "p-4"}>
                <EditGroup/>
            </div>
        </>
    )
}

export default withAuthorization(EditGroupPage, ["super_admin", "admin"]);
